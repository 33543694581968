import React from "react";
import classNames from "classnames";

import Stack from "@src/components/arrangement/Stack/Stack";
import Tooltip from "@src/components/dataDisplay/Tooltip/Tooltip";
import InfoCardIcon from "@src/assets/InfoCardIcon";

import "./StakingInfoCard.scss";

interface StakingInfoCardProps {
  id?: string;
  title?: string;
  tip?: string;
  tipWidth?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  active?: boolean;
  className?: string;
  children: React.ReactNode;
}

const StakingInfoCard = ({
  id,
  title,
  tip,
  tipWidth,
  disabled = false,
  fullWidth = false,
  active = false,
  className = "",
  children
}: StakingInfoCardProps) => {
  const classes = classNames(
    "staking-info-card",
    { "staking-info-card--active": active },
    { "full-width": fullWidth },
    { "card-disabled": disabled },
    className
  );

  return (
    <div id={id} className="staking-info-card-wrapper">
      {title && <p className="p0 bold">{title}</p>}
      <div className={classes}>
        {tip && (
          <div className="staking-info-card-info-icon">
            <Tooltip tip={tip || ""} width={tipWidth}>
              <InfoCardIcon />
            </Tooltip>
          </div>
        )}

        <Stack rowGap={4} fullSize alignItems="center" justifyContent="center" className="staking-info-card-content">
          {children}
        </Stack>
      </div>
    </div>
  );
};

export default StakingInfoCard;
