import { IChildren } from "src/types/IChildren";
import Navbar from "../layoutElements/Navbar/Navbar";
import Footer from "../layoutElements/Footer/Footer";
import CopyrightBar from "../layoutElements/CopyrightBar/CopyrightBar";
import "./AppLayout.scss";
import Container from "@src/components/arrangement/Container/Container";

const AppLayout = ({ children }: IChildren) => {
  return (
    <div className="app-layout">
      <header className="app-navbar">
        <Container size="xxl">
          <Navbar />
        </Container>
      </header>

      <main className="app-content">{children}</main>

      <footer className="app-footer">
        <div className="app-footer-content">
          <Container size="xxl">
            <Footer />
          </Container>
        </div>

        <div className="app-footer-copyright">
          <Container size="xxl">
            <CopyrightBar />
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default AppLayout;
