import { Outlet, useRoutes } from "react-router-dom";

import { PATHS } from "./paths/paths";

import { EthProvider } from "@src/services/eth/EthProvider";
import { StakingsDataProvider } from "@src/contexts/StakingsDataContext";
import { ModalsProvider } from "@src/contexts/ModalsContext";

import OfflineNetworkGuard from "@src/guards/OfflineNetworkGuard/OfflineNetworkGuard";
import MobileDeviceGuard from "@src/guards/MobileDeviceGuard/MobileDeviceGuard";
import BrowserGuard from "@src/guards/BrowserGuard/BrowserGuard";
import SwitchNetworkGuard from "@src/guards/SwitchNetworkGuard/SwitchNetworkGuard";
import DevModeGuard from "@src/guards/DevModeGuard/DevModeGuard";

import AppLayout from "@src/components/Layouts/AppLayout/AppLayout";
import Modals from "@src/components/modals/Modals";
import Staking from "@src/views/Staking/Staking";
import StakingInstances from "@src/views/StakingInstances/StakingInstances";
import NotFound from "@src/views/NotFound/NotFound";
import Debug from "@src/views/Debug/Debug";

const Routes = () =>
  useRoutes([
    {
      element: (
        <EthProvider>
          <OfflineNetworkGuard>
            <MobileDeviceGuard>
              <BrowserGuard>
                <SwitchNetworkGuard>
                  <StakingsDataProvider>
                    <ModalsProvider>
                      <AppLayout>
                        <Outlet />
                        <Modals />
                      </AppLayout>
                    </ModalsProvider>
                  </StakingsDataProvider>
                </SwitchNetworkGuard>
              </BrowserGuard>
            </MobileDeviceGuard>
          </OfflineNetworkGuard>
        </EthProvider>
      ),
      children: [
        { path: PATHS.main.path, element: <Staking /> },
        { path: PATHS.stakingInstances.path, element: <StakingInstances /> },
        { path: PATHS.notFound.path, element: <NotFound /> },
        {
          element: (
            <DevModeGuard>
              <Outlet />
            </DevModeGuard>
          ),
          children: [
            {
              path: PATHS.debug.path,
              element: <Debug />
            }
          ]
        }
      ]
    }
  ]);
export default Routes;
