import type { PropsWithChildren } from "react";
import { Chain, configureChains, createClient, WagmiConfig } from "wagmi";
import * as chains from "wagmi/chains";

import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "@wagmi/core/providers/public";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";

import { BlockchainAddress } from "@src/types/Blockchain.types";
import { ETH_NETWORK_KEY, ETH_INFURA_API_KEY, ETH_TOKEN_ADDRESS, ETH_AGGREGATOR_ADDRESS } from "@src/config";
export interface Config {
  network: Chain;
  infuraApiKey: string;
  tokenAddress: BlockchainAddress;
  aggregatorAddress: BlockchainAddress;
}

export const getNetwork = () => {
  const networkKey = ETH_NETWORK_KEY;

  const network = Object.entries({ ...chains }).find(([key]) => key === networkKey)?.[1];
  if (!network) throw new Error(`Network with key ${networkKey} not found`);

  return network;
};

export const config: Config = {
  network: getNetwork(),
  infuraApiKey: ETH_INFURA_API_KEY!,
  tokenAddress: ETH_TOKEN_ADDRESS as any,
  aggregatorAddress: ETH_AGGREGATOR_ADDRESS as any
};

export const connector = new MetaMaskConnector({
  chains: [getNetwork()],
  options: {
    shimDisconnect: true
  }
});

export const client = createClient({
  autoConnect: true,
  ...configureChains(
    [getNetwork()],
    [
      infuraProvider({ apiKey: config.infuraApiKey, stallTimeout: 2000 }),
      publicProvider()
      // publicProvider({ priority: 0 }),
      // jsonRpcProvider({
      //   priority: 1,
      //   rpc: (chain: any) => ({
      //     http: `https://rpc.${chain.network}.com`,
      //     webSocket: `wss://${chain.network}.infura.io/ws/v3/${config.infuraApiKey}`
      //   }),
      //   static: false,
      //   stallTimeout: 10000
      // })
    ],
    {
      pollingInterval: 300000,
      stallTimeout: 10000
      // minQuorum: 0,
      // targetQuorum: 0
    }
  ),
  connectors: [connector]
});

// client.provider.detectNetwork = () =>
//   Promise.resolve({
//     name: config.network.network,
//     chainId: config.network.id
//   });

// Object.defineProperty(client.provider, "_network", {
//   value: { name: config.network.network, chainId: config.network.id }
// });
// client.provider._networkPromise = Promise.resolve({ name: config.network.network, chainId: config.network.id });

export const EthProvider = ({ children }: PropsWithChildren) => {
  return <WagmiConfig client={client}>{children}</WagmiConfig>;
};
