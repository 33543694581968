import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";

import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";
import ConncectMetamask from "@src/components/dataEntry/Buttons/ConncectMetamask/ConncectMetamask";

const StakingNotConnected = () => {
  const { instancesData } = useStakingsData();
  const { t: commonT } = useTranslation("common");

  const monthsInfo = `${instancesData?.[0].months ?? 0} or ${instancesData?.[1].months ?? 0} months`;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar beforeInfo={monthsInfo} />
      <h2>{commonT("notConnected")}</h2>
      <ConncectMetamask fullSize />
    </Stack>
  );
};

export default StakingNotConnected;
