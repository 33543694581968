import { BigNumber } from "ethers";
import { prepareWriteContract, writeContract } from "wagmi/actions";
import { StakingAggregator__factory } from "staking-contract";

import { CURRENCY_SKEY } from "@src/constants/constants";

import { ConfirmTxModalProps } from "@src/components/modals/ConfirmTxModal/ConfirmTxModal";
import { SuccessTxModalProps } from "@src/components/modals/SuccessTxModal/SuccessTxModal";

import { config } from "../EthProvider";
import { TxHandler } from "./TxHandler";

export interface UnstakeTxCommand {
  type: "unstake";
  args: {};
}

export class UnstakeTxHandler extends TxHandler<UnstakeTxCommand> {
  private readonly GAS_LIMIT = 56249;

  override createConfirmModalProps(): ConfirmTxModalProps {
    return {
      ...super.createConfirmModalProps(),
      continueText: this.ctx.t("txModalUnstake.continue"),
      title: this.ctx.t("txModalUnstake.title"),
      textRows: [
        {
          key: this.ctx.t("txModalUnstake.rows.value"),
          value: `${this.formatSkey(this.ctx.stakedBalance)} ${CURRENCY_SKEY}`
        },
        {
          key: this.ctx.t("txModalUnstake.rows.after"),
          value: `${this.formatSkey(
            this.ctx.stakedBalance?.add(this.ctx.tokenBalance?.value ?? BigNumber.from(0))
          )} ${CURRENCY_SKEY}`
        }
      ]
    };
  }

  override createSuccessModalProps(): SuccessTxModalProps {
    return {
      ...super.createSuccessModalProps(),
      info: {
        description: this.ctx.t("txModalSuccessUnstake.info.description")
      }
    };
  }

  override async sendTx() {
    const tx = await prepareWriteContract({
      abi: StakingAggregator__factory.abi,
      address: config.aggregatorAddress,
      functionName: "withdrawDeposit",
      args: [BigNumber.from(0)]
    });

    await writeContract(tx).then((receipt) => receipt.wait());
  }
}
