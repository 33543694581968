import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking } from "@src/services/eth/EthHooks";

import { BlockchainAddress } from "@src/types/Blockchain.types";
import { convertMiliseconds } from "@src/utils/convertMiliseconds";
import { formatDate } from "@src/utils/formatDateTime";

import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";

const ClaimingBeforeAssigned = () => {
  const { data: assignedStaking } = useAssignedStaking();
  const { instancesData, getInstanceData } = useStakingsData();

  const { t } = useTranslation("staking");
  const { t: commonT } = useTranslation("common");

  const assignedInstance = useMemo(() => {
    return getInstanceData(assignedStaking as BlockchainAddress);
  }, [instancesData, getInstanceData, assignedStaking]);

  const startDate = useMemo(() => {
    return new Date(instancesData?.[0].stages.earnStageTo || 0);
  }, [assignedInstance]);

  const claimingDurationMs =
    (assignedInstance && assignedInstance.stages.claimStageTo - assignedInstance.stages.earnStageTo) || 0;
  const beforeInfo = `${convertMiliseconds(claimingDurationMs)} ${commonT("months")}`;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar beforeInfo={beforeInfo} />
      <h2 className="gradient-text">{t("claimingStage.beforeAssigned.title", { date: formatDate(startDate) })}</h2>
    </Stack>
  );
};

export default ClaimingBeforeAssigned;
