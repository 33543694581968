import layoutEn from "@locales/en/layout.json";
import guardsEn from "@locales/en/guards.json";
import modalsEn from "@locales/en/modals.json";
import stakingEn from "@locales/en/staking.json";
import formErrorsEn from "@locales/en/form-errors.json";
import notFoundEn from "@locales/en/not-found.json";
import commonEn from "@locales/en/common.json";
import faqEn from "@locales/en/faq.json";
import blockchainErrorsEn from "@locales/en/blockchain-errors.json";
import agreementsEn from "@locales/en/agreements.json";

export const resources = {
  en: {
    layout: layoutEn,
    guards: guardsEn,
    modals: modalsEn,
    staking: stakingEn,
    formErrors: formErrorsEn,
    notFound: notFoundEn,
    common: commonEn,
    faq: faqEn,
    blockchainErrors: blockchainErrorsEn,
    agreements: agreementsEn
  }
} as const;
