import React from "react";
import { BrowserRouter } from "react-router-dom";

import Routes from "./router/routes";

import "@translations/i18n";

import "@scss/utils.scss";
import "@scss/base.scss";
import "@scss/lib.scss";
import "@scss/variables.scss";
import "@scss/mixins.scss";
import "@scss/responsive.scss";

const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
