import React from "react";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { numberWithSpaces } from "@src/utils/numberWithSpaces";

import Container from "@src/components/arrangement/Container/Container";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import StakingProgress from "@src/components/dataDisplay/StakingProgress/StakingProgress";

import "./StakingInstances.scss";

const StakingInstances = () => {
  const { instancesData } = useStakingsData();

  return (
    <Container size="xl" className="mt-5 mb-10">
      <h2 className="text-center mb-5">Staking instances info</h2>
      {instancesData === null && <p className="p0 danger text-center">Invalid staking instances data</p>}

      <div className="staking-instances">
        {instancesData !== null &&
          instancesData.map((instance, index) => {
            const { data, addr, months } = instance;

            return (
              <Stack key={addr} rowGap={20} fullWidth className="mb-2">
                <hr />
                <h4 className="mb-3">
                  <span className="bold">Staking instance {index}:</span> <span className="fs-6">{addr}</span>
                </h4>

                <StakingProgress
                  currentTotalDeposit={data.currentTotalDeposit.number}
                  maxTotalStake={data.maxTotalStake.number}
                />

                <Group colGap={50} alignItems="flex-start" className="">
                  <Stack>
                    <p className="p1">
                      <span className="bold">Earnings percent: </span>
                      {data.earningPercent.percent}
                    </p>
                    <p className="p1">
                      <span className="bold">Staking duration: </span>
                      {months} months
                    </p>
                    <p className="p1">
                      <span className="bold">Current total deposit: </span>
                      {numberWithSpaces(data.currentTotalDeposit.number || 0)}
                    </p>
                    <p className="p1">
                      <span className="bold">Max total stake: </span>
                      {numberWithSpaces(data.maxTotalStake.number || 0)}
                    </p>
                  </Stack>

                  <Stack>
                    <p className="p1">
                      <span className="bold">Max user stake: </span>
                      {numberWithSpaces(data.maxUserStake.number || 0)}
                    </p>
                    <p className="p1">
                      <span className="bold">Earnings quota: </span>
                      {numberWithSpaces(data.earningsQuota.number || 0)}
                    </p>
                    <p className="p1">
                      <span className="bold">Unused quota: </span>
                      {numberWithSpaces(data.unusedQuota.number || 0)}
                    </p>
                  </Stack>
                </Group>
              </Stack>
            );
          })}
      </div>
    </Container>
  );
};

export default StakingInstances;
