import React from "react";
import classNames from "classnames";
import "./StageCard.scss";

interface StageCardProps {
  id?: string;
  active?: boolean;
  withoutArrow?: boolean;
  className?: string;
  children: React.ReactNode;
}

const StageCard = ({ id, active = false, withoutArrow = false, className, children }: StageCardProps) => {
  const containerClasess = classNames(
    "stage-card",
    { "stage-card-active": active, "stage-card-inactive": !active },
    className
  );

  const triangleClasses = classNames("stage-card-triangle", { "hidden-triangle": withoutArrow });

  return (
    <div id={id} className={containerClasess}>
      {children}
      <div className={triangleClasses} />
    </div>
  );
};

export default StageCard;
