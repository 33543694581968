import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { formatDate } from "@src/utils/formatDateTime";

import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";

const StakingBefore = () => {
  const { instancesData } = useStakingsData();

  const { t } = useTranslation("staking");

  const startDate = useMemo(() => {
    return new Date(instancesData?.[0].stages.subscribeStageTo || 0);
  }, [instancesData]);

  const monthsInfo = `${instancesData?.[0].months ?? 0} or ${instancesData?.[1].months ?? 0} months`;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar beforeInfo={monthsInfo} />
      <h2 className="gradient-text">{t("stakingStage.before.title", { date: formatDate(startDate) })}</h2>
    </Stack>
  );
};

export default StakingBefore;
