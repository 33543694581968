import React from "react";
import classNames from "classnames";

import Spinner from "@src/components/ux/Spinner/Spinner";

import "./Button.scss";

type ButtonVariant = "primary" | "secondary" | "text";

export interface ButtonProps {
  children: React.ReactNode;
  variant?: ButtonVariant;
  id?: string;
  name?: string;
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  fullSize?: boolean;
  freeWidth?: boolean;
  uppercase?: boolean;
  className?: string;
  onClick?(): void;
}

const Button = ({
  children,
  variant = "primary",
  id,
  name,
  title = "",
  loading = false,
  disabled = false,
  fullSize = false,
  freeWidth = true,
  uppercase = false,
  className = "",
  onClick = () => {}
}: ButtonProps) => {
  const classes = classNames(
    "button",
    variant,
    className,
    { "button-loading": loading },
    { "full-size": fullSize },
    { "full-width": freeWidth },
    { "text-uppercase": uppercase }
  );

  const spinnerVariant = variant === "primary" ? "secondary" : "primary";

  return (
    <button id={id} name={name} title={title} disabled={disabled} onClick={onClick} className={classes}>
      <div className="button-content">
        {children}
        {loading && <Spinner variant={spinnerVariant} />}
      </div>
    </button>
  );
};

export default Button;
