import React from "react";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

import Portal from "@src/components/overlay/Portal/Portal";
import Backdrop from "@src/components/ux/Backdrop/Backdrop";
import Modal from "@src/components/overlay/Modal/Modal";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import Button from "@src/components/dataEntry/Buttons/Button/Button";

import { supportedBrowsers } from "@src/data/supportedBrowsers";

import "./UnsupportedBrowser.scss";

interface UnsupportedBrowserProps {
  onClose(): void;
}

const UnsupportedBrowser = ({ onClose }: UnsupportedBrowserProps) => {
  const { t } = useTranslation("guards");
  const { t: commonT } = useTranslation("common");

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <Stack alignItems="center" className="unsupported-browser">
            <h3 className="bold text-center mt-2 mb-2">{t("unsupportedBrowser.title")}</h3>
            <p className="p0 text-center">{t("unsupportedBrowser.listBrowser")}</p>

            <Group colGap={32} justifyContent="center" fullWidth className="mt-4">
              {supportedBrowsers.map(({ browser, path, alt }) => (
                <Stack key={nanoid()} rowGap={16} alignItems="center">
                  <img src={path} alt={alt} width={50} height={50} />
                  <p className="p1">{browser}</p>
                </Stack>
              ))}
            </Group>

            <Button variant="secondary" fullSize onClick={onClose} className="mt-5">
              {commonT("close")}
            </Button>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default UnsupportedBrowser;
