import React from "react";

interface Icon {
  checked?: boolean;
  className?: string;
}

const CheckedCardIcon = ({ checked = false, className }: Icon) => {
  return checked ? (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.9998 34.8334C16.8096 34.8334 14.7512 34.4175 12.8248 33.5858C10.8984 32.755 9.22275 31.6272 7.79775 30.2022C6.37275 28.7772 5.24489 27.1015 4.41417 25.1751C3.58239 23.2487 3.1665 21.1904 3.1665 19.0001C3.1665 16.8098 3.58239 14.7515 4.41417 12.8251C5.24489 10.8987 6.37275 9.223 7.79775 7.798C9.22275 6.373 10.8984 5.24461 12.8248 4.41283C14.7512 3.58211 16.8096 3.16675 18.9998 3.16675C21.1901 3.16675 23.2484 3.58211 25.1748 4.41283C27.1012 5.24461 28.7769 6.373 30.2019 7.798C31.6269 9.223 32.7548 10.8987 33.5855 12.8251C34.4173 14.7515 34.8332 16.8098 34.8332 19.0001C34.8332 21.1904 34.4173 23.2487 33.5855 25.1751C32.7548 27.1015 31.6269 28.7772 30.2019 30.2022C28.7769 31.6272 27.1012 32.755 25.1748 33.5858C23.2484 34.4175 21.1901 34.8334 18.9998 34.8334Z"
        fill="url(#paint0_linear_406_3559)"
      />
      <path
        d="M13.3 17.8917L16.7438 21.3354L24.5813 13.4979C24.8715 13.2076 25.241 13.0625 25.6896 13.0625C26.1382 13.0625 26.5076 13.2076 26.7979 13.4979C27.0882 13.7882 27.2402 14.1508 27.2539 14.5857C27.2666 15.0216 27.1278 15.3847 26.8375 15.675L17.8521 24.6604C17.5618 24.9507 17.1924 25.0958 16.7438 25.0958C16.2951 25.0958 15.9257 24.9507 15.6354 24.6604L11.1229 20.1479C10.8326 19.8576 10.6875 19.4882 10.6875 19.0396C10.6875 18.591 10.8326 18.2215 11.1229 17.9313C11.4132 17.641 11.7763 17.4895 12.2122 17.4768C12.6471 17.4631 13.0097 17.6014 13.3 17.8917Z"
        fill="#FAFAFC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_406_3559"
          x1="34.8332"
          y1="33.0787"
          x2="3.98688"
          y2="32.9946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3495FF" />
          <stop offset="1" stopColor="#5C44EC" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={38}
      height={38}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.1332 18.9001L11.2332 16.0001C10.9887 15.7556 10.6834 15.6392 10.3172 15.6507C9.95006 15.6614 9.64428 15.789 9.39984 16.0334C9.15539 16.2779 9.03317 16.589 9.03317 16.9667C9.03317 17.3445 9.15539 17.6556 9.39984 17.9001L13.1998 21.7001C13.4443 21.9445 13.7554 22.0667 14.1332 22.0667C14.5109 22.0667 14.8221 21.9445 15.0665 21.7001L22.6332 14.1334C22.8776 13.889 22.9945 13.5832 22.9838 13.2161C22.9723 12.8499 22.8443 12.5445 22.5998 12.3001C22.3554 12.0556 22.0443 11.9334 21.6665 11.9334C21.2887 11.9334 20.9776 12.0556 20.7332 12.3001L14.1332 18.9001ZM15.9998 29.8334C14.1554 29.8334 12.4221 29.4832 10.7998 28.7827C9.17762 28.0832 7.7665 27.1334 6.5665 25.9334C5.3665 24.7334 4.41673 23.3223 3.71717 21.7001C3.01673 20.0779 2.6665 18.3445 2.6665 16.5001C2.6665 14.6556 3.01673 12.9223 3.71717 11.3001C4.41673 9.67786 5.3665 8.26675 6.5665 7.06675C7.7665 5.86675 9.17762 4.91653 10.7998 4.21608C12.4221 3.51653 14.1554 3.16675 15.9998 3.16675C17.8443 3.16675 19.5776 3.51653 21.1998 4.21608C22.8221 4.91653 24.2332 5.86675 25.4332 7.06675C26.6332 8.26675 27.5829 9.67786 28.2825 11.3001C28.9829 12.9223 29.3332 14.6556 29.3332 16.5001C29.3332 18.3445 28.9829 20.0779 28.2825 21.7001C27.5829 23.3223 26.6332 24.7334 25.4332 25.9334C24.2332 27.1334 22.8221 28.0832 21.1998 28.7827C19.5776 29.4832 17.8443 29.8334 15.9998 29.8334ZM15.9998 27.1667C18.9554 27.1667 21.4723 26.1281 23.5505 24.0507C25.6278 21.9725 26.6665 19.4556 26.6665 16.5001C26.6665 13.5445 25.6278 11.0276 23.5505 8.94941C21.4723 6.87208 18.9554 5.83341 15.9998 5.83341C13.0443 5.83341 10.5278 6.87208 8.4505 8.94941C6.37228 11.0276 5.33317 13.5445 5.33317 16.5001C5.33317 19.4556 6.37228 21.9725 8.4505 24.0507C10.5278 26.1281 13.0443 27.1667 15.9998 27.1667Z"
        fill="#B8B7C1"
      />
    </svg>
  );
};

export default CheckedCardIcon;
