import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import CollapseArrow from "@src/assets/CollapseArrow";
import "./CollapseDepositMore.scss";

interface CollapseDepositMoreProps {
  title?: string;
  className?: string;
}

const CollapseDepositMore = ({ title, className }: CollapseDepositMoreProps) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation("common");

  const handleToggle = () => setActive((prevState) => !prevState);

  const classes = classNames("collapse-deposit-more", { "collapse-deposit-more-active": active }, className);

  return (
    <div role="button" className={classes} onClick={handleToggle}>
      <p className="p0">{title || t("depositMore")}</p>
      <CollapseArrow className="collapse-deposit-more-icon" />
    </div>
  );
};

export default CollapseDepositMore;
