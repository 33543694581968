import { BigNumber, UnsignedTransaction, ethers } from "ethers";
import { FetchFeeDataResult } from "@wagmi/core";

import { StakingAggregator, StakingAggregator__factory, Staking__factory } from "staking-contract";
import { getCurrentDateMiliseconds } from "@src/utils/getCurrentDate";

export type StakingDataStruct = Awaited<ReturnType<StakingAggregator["getInstances"]>>[number]["data"];

// prettier-ignore
export type StakingStatus =
  | "pending"     // Nothing can be done yet
  | "subscribe"   // Users can subscribe to staking
  | "earn"        // Funds are locked. Wait for end of this stage
  | "claim"       // Users can claim their earnings
  | "finished"    // Staking is finished. Nothing can be done
  | "unknown" // Staking stage unknown

export const getStakingStatus = (data?: StakingDataStruct): StakingStatus => {
  if (!data) return "unknown";

  // Smart contract has timestamps in seconds format
  const now = getCurrentDateMiliseconds() / 1000;

  if (now < data.subscribeStageFrom) return "pending";
  if (now >= data.subscribeStageFrom && now < data.subscribeStageTo) return "subscribe";
  if (now >= data.subscribeStageTo && now < data.earnStageTo) return "earn";
  if (now >= data.earnStageTo && now < data.claimStageTo) return "claim";
  if (now >= data.claimStageTo) return "finished";

  return "unknown";
};

export const getTxFee = (tx: Partial<UnsignedTransaction>, feeData?: FetchFeeDataResult | null) => {
  return BigNumber.from(tx?.gasLimit ?? 0).mul(feeData?.maxFeePerGas ?? 0);
};

export const decodeError = (hash: string) => {
  return (
    [Staking__factory, StakingAggregator__factory]
      .reduce(
        (acc, next) => [...acc, ...Object.values(new ethers.utils.Interface(next.abi).errors)],
        [] as ethers.utils.ErrorFragment[]
      )
      .map((err) => ({
        ...err,
        id: ethers.utils.id(err.format()).slice(2, 10)
      }))
      .find((err) => err.id === hash.replace("0x", ""))?.name ?? null
  );
};
