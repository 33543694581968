import React, { useEffect } from "react";

import { IChildren } from "@src/types/IChildren";
import useVisible from "@src/hooks/useVisible";
import useNetworkStatus from "@src/hooks/useNetworkStatus";

import OfflineNetwork from "./OfflineNetwork/OfflineNetwork";

const OfflineNetworkGuard = ({ children }: IChildren) => {
  const { isVisible, setVisibilityTrue, setVisibilityFalse } = useVisible();
  const isOnline = useNetworkStatus();

  useEffect(() => {
    if (!isOnline) setVisibilityTrue();
    else setVisibilityFalse();
  }, [isOnline]);

  return (
    <>
      {children}
      {isVisible && <OfflineNetwork />}
    </>
  );
};

export default OfflineNetworkGuard;
