export const SKEY_NETWORK_URL = "https://skey.network/";

export const CURRENCY_SKEY = "SKEY";

// SOCIAL MEDIA LINK'S *************************************************************

export const LINKED_IN = "https://www.linkedin.com/company/skeynetwork";
export const TWITTER = "https://twitter.com/skeynetwork";
export const YOUTUBE = "https://www.youtube.com/channel/UC6oK-S1xW9yysYFAXBou6sA";
export const REDDIT = "https://www.reddit.com/r/skeynetwork/";
export const FACEBOOK = "https://www.facebook.com/SkeyNetwork";
export const DISCORD = "https://discord.com/invite/en7YQU4EBR";
export const KAKAO_TALK = "https://open.kakao.com/o/g9fgzwPc";
export const MEDIUM = "https://skeynetwork.medium.com/";

export const SOCIAL_MEDIA_LINKS = { LINKED_IN, TWITTER, YOUTUBE, REDDIT, FACEBOOK, DISCORD, KAKAO_TALK, MEDIUM };

export const TELEGRAM_1 = "https://t.me/SkeyNetworkNews";
export const TELEGRAM_2 = "https://t.me/SkeyNetworkChat";
export const TELEGRAM_3 = "https://t.me/SmartKeyChat_Korea";
export const TELEGRAM_4 = "https://t.me/SkeyNetworkChatPL";
export const TELEGRAM_5 = "https://t.me/SmartKeyChatFr";

export const TELEGRAM_CHANNELLS = { TELEGRAM_1, TELEGRAM_2, TELEGRAM_3, TELEGRAM_4, TELEGRAM_5 };

// SOCIAL MEDIA LINK'S *************************************************************

// OTHER LINK'S ********************************************************************

export const METAMASK_DOWNLOAD_URL = "https://metamask.io/download";

// OTHER LINK'S ********************************************************************

// EMAIL'S *************************************************************************

export const SKEY_MAIL = "office@skey.network";
export const SKEY_SUPPORT = "support@skey.network";

// EMAIL'S *************************************************************************

// DOCUMENT'S  *********************************************************************

export const STAKING_TERMS = "/pdfs/Skey_Network_Staking_Terms.pdf";

// DOCUMENT'S  *********************************************************************
