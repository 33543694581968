import React, { useState } from "react";

import { IContext } from "@src/types/IContext.types";
import { ConfirmTxModalProps } from "@src/components/modals/ConfirmTxModal/ConfirmTxModal";
import { LoadingTxModalProps } from "@src/components/modals/LoadingTxModal/LoadingTxModal";
import { SuccessTxModalProps } from "@src/components/modals/SuccessTxModal/SuccessTxModal";
import { ErrorTxModalProps } from "@src/components/modals/ErrorTxModal/ErrorTxModal";

export interface WithoutObjectModal {
  type: "metamaskUnavailable";
  object: null;
}

export interface IConfirmTxModal {
  type: "confirmTxModal";
  object: ConfirmTxModalProps;
}
export interface ILoadingTxModal {
  type: "loadingTxModal";
  object: LoadingTxModalProps;
}

export interface ISuccessTxModal {
  type: "successTxModal";
  object: SuccessTxModalProps;
}

export interface IErrorTxModal {
  type: "errorTxModal";
  object: ErrorTxModalProps;
}

export type Modal = WithoutObjectModal | IConfirmTxModal | ILoadingTxModal | ISuccessTxModal | IErrorTxModal;

export interface ContextValue {
  activeModal: Modal | null;
  openModal: <T extends Modal>(type: T["type"], object?: T["object"]) => void;
  closeModal: () => void;
}

const ModalsContext = React.createContext(null as any);

export const ModalsProvider = ({ children }: IContext) => {
  const [activeModal, setActiveModal] = useState<Modal | null>(null);

  const openModal: ContextValue["openModal"] = (type, object) =>
    setActiveModal({ type, object: object ?? null } as Modal);
  const closeModal = () => setActiveModal(null);

  const contextValue: ContextValue = {
    activeModal,
    openModal,
    closeModal
  };

  return <ModalsContext.Provider value={contextValue}>{children}</ModalsContext.Provider>;
};

export const useModals = (): ContextValue => React.useContext(ModalsContext);
