import React from "react";
import classNames from "classnames";
import { IChildren } from "src/types/IChildren";
import "./Group.scss";

export interface IGroup {
  colGap?: number;
  rowGap?: number;
  justifyContent?: React.CSSProperties["justifyContent"];
  alignItems?: React.CSSProperties["alignItems"];
  wrap?: React.CSSProperties["flexWrap"];
  fullSize?: boolean;
  fullWidth?: boolean;
  className?: string;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onClick?(): void;
}

const Group = ({
  colGap = 0,
  rowGap = 0,
  children,
  justifyContent = "flex-start",
  alignItems = "center",
  wrap,
  fullSize = false,
  fullWidth = false,
  className = "",
  onMouseEnter,
  onMouseLeave,
  onClick = () => {}
}: IGroup & IChildren) => {
  const classes = classNames("group", { "full-width": fullWidth }, { "full-size": fullSize }, className);
  return (
    <div
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={{
        gap: `${rowGap}px ${colGap}px`,
        justifyContent,
        alignItems,
        flexWrap: wrap
      }}
    >
      {children}
    </div>
  );
};

export default Group;
