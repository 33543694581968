import React from "react";
import { useTranslation } from "react-i18next";

import { SKEY_NETWORK_URL } from "@src/constants/constants";
import Group from "@src/components/arrangement/Group/Group";
import SkeyNetworkPin from "@src/assets/SkeyNetworkPin";

import "./SkeyNetworkLink.scss";

const SkeyNetworkLink = () => {
  const { t } = useTranslation("common");

  return (
    <a
      href={SKEY_NETWORK_URL}
      target="_blank"
      rel="noopener noreferrer nofollow"
      title={t("skeyNetworkLink")}
      className="skey-network-link"
    >
      <Group colGap={4}>
        <SkeyNetworkPin />
        skey.network
      </Group>
    </a>
  );
};

export default SkeyNetworkLink;
