import React from "react";

interface Icon {
  width?: number;
  height?: number;
  className?: string;
}

const InfoCardIcon = ({ width = 20, height = 20, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.99984 14.1667C10.2359 14.1667 10.434 14.0867 10.594 13.9267C10.7534 13.7673 10.8332 13.5695 10.8332 13.3334V9.97925C10.8332 9.74314 10.7534 9.54869 10.594 9.39591C10.434 9.24314 10.2359 9.16675 9.99984 9.16675C9.76373 9.16675 9.56595 9.24647 9.4065 9.40591C9.2465 9.56591 9.1665 9.76397 9.1665 10.0001V13.3542C9.1665 13.5904 9.2465 13.7848 9.4065 13.9376C9.56595 14.0904 9.76373 14.1667 9.99984 14.1667ZM9.99984 7.50008C10.2359 7.50008 10.434 7.42008 10.594 7.26008C10.7534 7.10064 10.8332 6.90286 10.8332 6.66675C10.8332 6.43064 10.7534 6.23258 10.594 6.07258C10.434 5.91314 10.2359 5.83341 9.99984 5.83341C9.76373 5.83341 9.56595 5.91314 9.4065 6.07258C9.2465 6.23258 9.1665 6.43064 9.1665 6.66675C9.1665 6.90286 9.2465 7.10064 9.4065 7.26008C9.56595 7.42008 9.76373 7.50008 9.99984 7.50008ZM9.99984 18.3334C8.84706 18.3334 7.76373 18.1145 6.74984 17.6767C5.73595 17.2395 4.854 16.6459 4.104 15.8959C3.354 15.1459 2.76039 14.264 2.32317 13.2501C1.88539 12.2362 1.6665 11.1529 1.6665 10.0001C1.6665 8.8473 1.88539 7.76397 2.32317 6.75008C2.76039 5.73619 3.354 4.85425 4.104 4.10425C4.854 3.35425 5.73595 2.76036 6.74984 2.32258C7.76373 1.88536 8.84706 1.66675 9.99984 1.66675C11.1526 1.66675 12.2359 1.88536 13.2498 2.32258C14.2637 2.76036 15.1457 3.35425 15.8957 4.10425C16.6457 4.85425 17.2393 5.73619 17.6765 6.75008C18.1143 7.76397 18.3332 8.8473 18.3332 10.0001C18.3332 11.1529 18.1143 12.2362 17.6765 13.2501C17.2393 14.264 16.6457 15.1459 15.8957 15.8959C15.1457 16.6459 14.2637 17.2395 13.2498 17.6767C12.2359 18.1145 11.1526 18.3334 9.99984 18.3334ZM9.99984 16.6667C11.8471 16.6667 13.4201 16.0176 14.719 14.7192C16.0173 13.4204 16.6665 11.8473 16.6665 10.0001C16.6665 8.15286 16.0173 6.5798 14.719 5.28091C13.4201 3.98258 11.8471 3.33341 9.99984 3.33341C8.15262 3.33341 6.57984 3.98258 5.2815 5.28091C3.98261 6.5798 3.33317 8.15286 3.33317 10.0001C3.33317 11.8473 3.98261 13.4204 5.2815 14.7192C6.57984 16.0176 8.15262 16.6667 9.99984 16.6667Z"
      fill="#B8B7C1"
    />
  </svg>
);

export default InfoCardIcon;
