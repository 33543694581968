import React from "react";

import Container from "@src/components/arrangement/Container/Container";
import Hero from "./sections/Hero/Hero";
import PrelaunchStage from "./sections/PrelaunchStage/PrelaunchStage";
import StakingStage from "./sections/StakingStage/StakingStage";
import ClaimingStage from "./sections/ClaimingStage/ClaimingStage";
import Faq from "./sections/Faq/Faq";

import "./Staking.scss";

const Staking = () => {
  return (
    <div className="staking">
      <Hero />
      <Container size="xxl">
        <PrelaunchStage />
        <StakingStage />
        <ClaimingStage />
        <Faq />
      </Container>
    </div>
  );
};

export default Staking;
