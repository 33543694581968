import React from "react";
import useCountdownTimer from "./useCountdownTimer";
import "./CountdownTimer.scss";

interface CountdownTimerProps {
  targetDate: number;
}

const formatLeftTimeValue = (value: number) => value.toString().padStart(2, "0");

const CountdownTimer = ({ targetDate }: CountdownTimerProps) => {
  const { days, hours, minutes, seconds } = useCountdownTimer(new Date(targetDate));

  return (
    <div className="countdown-timer">
      <div className="countdown-timer-column">
        <span className="timer-value">{days}</span>
        <span className="timer-value-name">days</span>
      </div>

      <div className="countdown-timer-time-section">
        <div className="countdown-timer-column with-values">
          <span className="timer-value">{formatLeftTimeValue(hours)}</span>
          <span className="timer-value-name">hours</span>
        </div>

        <div className="countdown-timer-column">
          <span className="timer-value">:</span>
        </div>

        <div className="countdown-timer-column with-values">
          <span className="timer-value">{formatLeftTimeValue(minutes)}</span>
          <span className="timer-value-name">minutes</span>
        </div>

        <div className="countdown-timer-column">
          <span className="timer-value">:</span>
        </div>

        <div className="countdown-timer-column with-values">
          <span className="timer-value">{formatLeftTimeValue(seconds)}</span>
          <span className="timer-value-name">seconds</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
