import React from "react";
import classNames from "classnames";
import "./ModalStatusIcon.scss";

interface Icon {
  variant?: "loading" | "success" | "error";
  width?: number;
  height?: number;
  className?: string;
}

const LoadingIcon = ({ width = 80, height = 80, className }: Icon) => {
  const classes = classNames("modal-status-loading", className);

  return (
    <div className={classes}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="modal-status-loading-svg"
      >
        <circle cx={40} cy={40} r={37} className="modal-status-loading-svg-circle" />
        <defs>
          <linearGradient
            id="modal-loader-gradient"
            x1="80"
            y1="75.5671"
            x2="2.07254"
            y2="75.3546"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3495FF" />
            <stop offset="1" stopColor="#5C44EC" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

const SuccessIcon = ({ width = 80, height = 80, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM4.11502 40C4.11502 59.8187 20.1813 75.885 40 75.885C59.8187 75.885 75.885 59.8187 75.885 40C75.885 20.1813 59.8187 4.11502 40 4.11502C20.1813 4.11502 4.11502 20.1813 4.11502 40Z"
      fill="#3AA12F"
    />
    <path d="M23 39L36 52L60 28" stroke="#3AA12F" strokeWidth="4" />
  </svg>
);

const ErrorIcon = ({ width = 80, height = 80, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM4.11502 40C4.11502 59.8187 20.1813 75.885 40 75.885C59.8187 75.885 75.885 59.8187 75.885 40C75.885 20.1813 59.8187 4.11502 40 4.11502C20.1813 4.11502 4.11502 20.1813 4.11502 40Z"
      fill="#E63A1A"
    />
    <path d="M28 52L52 28" stroke="#E63A1A" strokeWidth="4" />
    <path d="M52 52L28 28" stroke="#E63A1A" strokeWidth="4" />
  </svg>
);

const ModalStatusIcon = ({ variant = "loading", width = 80, height = 80, className }: Icon) => {
  switch (variant) {
    case "loading":
      return <LoadingIcon width={width} height={height} className={className} />;
    case "success":
      return <SuccessIcon width={width} height={height} className={className} />;
    case "error":
      return <ErrorIcon width={width} height={height} className={className} />;
    default:
      return <LoadingIcon width={width} height={height} className={className} />;
  }
};

export default ModalStatusIcon;
