export type AppStage = "dev" | "prod" | null;

const APP_STAGE_ENV: AppStage = (process.env.REACT_APP_STAGE as AppStage) || null;
const ETH_INFURA_API_KEY_ENV = process.env.REACT_APP_ETH_INFURA_API_KEY || null;
const ETH_TOKEN_ADDRESS_ENV = process.env.REACT_APP_ETH_TOKEN_ADDRESS || null;
const ETH_AGGREGATOR_ADDRESS_ENV = process.env.REACT_APP_ETH_AGGREGATOR_ADDRESS || null;

const getAppStage = () => {
  if (!APP_STAGE_ENV) {
    throw new Error(`There is not REACT_APP_STAGE provided in env`);
  }
  return APP_STAGE_ENV;
};

const getDevShiftTime = () => {
  if (APP_STAGE === "dev" && process.env.REACT_APP_DEV_SHIFT_TIME) {
    return Number(process.env.REACT_APP_DEV_SHIFT_TIME);
  }
  return 0;
};

const getInfuraApiKey = () => {
  if (!ETH_INFURA_API_KEY_ENV) {
    throw new Error(`There is not REACT_APP_ETH_INFURA_API_KEY provided in env`);
  }
  return ETH_INFURA_API_KEY_ENV;
};

const getTokenAddress = () => {
  if (!ETH_TOKEN_ADDRESS_ENV) {
    throw new Error(`There is not REACT_APP_ETH_TOKEN_ADDRESS provided in env`);
  }
  return ETH_TOKEN_ADDRESS_ENV;
};

const getAggregatorAddress = () => {
  if (!ETH_AGGREGATOR_ADDRESS_ENV) {
    throw new Error(`There is not REACT_APP_ETH_AGGREGATOR_ADDRESS provided in env`);
  }
  return ETH_AGGREGATOR_ADDRESS_ENV;
};

export const APP_STAGE = getAppStage();
export const DEV_SHIFT_TIME = getDevShiftTime();
// export const DEV_SHIFT_TIME:number = -3034518022;

export const ETH_NETWORK_KEY = process.env.REACT_APP_ETH_NETWORK_KEY ?? "sepolia";
export const ETH_INFURA_API_KEY = getInfuraApiKey();
export const ETH_TOKEN_ADDRESS = getTokenAddress();
export const ETH_AGGREGATOR_ADDRESS = getAggregatorAddress();

export const SKEY_DECIMALS = Number(process.env.REACT_APP_SKEY_DECIMALS) || 8;
export const SKEY_TOTAL_SUPPLY = Number(process.env.REACT_APP_SKEY_TOTAL_SUPPLY) || 1000000000;

export const I18N_DEBUG_MODE = process.env.REACT_APP_I18N_DEBUG_MODE === "true";
export const UPDATE_STAKING_DATA_TIME = Number(process.env.REACT_APP_UPDATE_STAKING_DATA_TIME) || 1000;
