import React, { useMemo } from "react";
import { useNetwork } from "wagmi";
import { useTranslation } from "react-i18next";

import { config } from "@src/services/eth/EthProvider";
import { formatWalletAddress } from "@src/utils/formatWalletAddress";
import { copyToClipboard } from "@src/utils/copyToClipboard";

import Tooltip from "@src/components/dataDisplay/Tooltip/Tooltip";

import "./WalletAddress.scss";

interface WalletAddressProps {
  address: string;
}

const WalletAddress = ({ address }: WalletAddressProps) => {
  const { t } = useTranslation("common");
  const network = useNetwork();

  const correctNetwork = useMemo(() => {
    return network?.chain?.id === config.network.id;
  }, [network, config]);

  const formattedAddress = formatWalletAddress(address);
  const handleCopyToClipboard = () => copyToClipboard(address);

  const walletAddressIconPath = correctNetwork
    ? "/images/wallet-address-icon.svg"
    : "/images/wallet-address-incorrect-network-icon.svg";

  return (
    <Tooltip tip={t("copyWalletAddress")}>
      <div title={t("copyWalletAddress")} className="wallet-address" onClick={handleCopyToClipboard}>
        <p className="p1">{formattedAddress}</p>
        <img src={walletAddressIconPath} alt="wallet address" width={21} height={20} />
      </div>
    </Tooltip>
  );
};

export default WalletAddress;
