import React from "react";
import { useAccount } from "wagmi";

import Portal from "@src/components/overlay/Portal/Portal";
import Backdrop from "@src/components/ux/Backdrop/Backdrop";
import Modal from "@src/components/overlay/Modal/Modal";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import WalletAddress from "@src/components/common/WalletAddress/WalletAddress";
import ModalStatusIcon from "@src/components/ux/ModalStatusIcon/ModalStatusIcon";

import "./LoadingTxModal.scss";
import { useTranslation } from "react-i18next";

export interface LoadingTxModalProps {
  title?: string;
  info?: {
    title?: string;
    description?: string;
  };
}

const LoadingTxModal = ({ title, info }: LoadingTxModalProps) => {
  const { address } = useAccount();
  const { t } = useTranslation("modals");

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack rowGap={24} alignItems="center" className="loading-tx-modal">
            <Group justifyContent="flex-end" fullWidth>
              <WalletAddress address={address ?? ""} />
            </Group>
            <ModalStatusIcon />

            <h2>{title ?? t("txModalLoadingDefault.title")}</h2>
            <div className="description text-center">
              <p className="p0 bold">{info?.title ?? t("txModalLoadingDefault.info.title")}</p>
              <p className="p0">{info?.description ?? t("txModalLoadingDefault.info.description")}</p>
            </div>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default LoadingTxModal;
