import React from "react";

interface Icon {
  width?: number;
  height?: number;
  className?: string;
}

const OfflineIcon = ({ width = 104, height = 104, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M52 53.2558V39.9907"
      stroke="url(#paint0_linear_362_3580)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52 63.8677V63.9947"
      stroke="url(#paint1_linear_362_3580)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M103 52C103 80.1671 80.1671 103 52 103C23.8329 103 1 80.1671 1 52C1 23.8329 23.8329 1 52 1C80.1671 1 103 23.8329 103 52Z"
      stroke="url(#paint2_linear_362_3580)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M77.5 52C77.5 80.1671 66.0836 103 52 103C37.9164 103 26.5 80.1671 26.5 52C26.5 23.8329 37.9164 1 52 1C66.0836 1 77.5 23.8329 77.5 52Z"
      stroke="url(#paint3_linear_362_3580)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M90.2427 18.2732C80.9007 23.5651 67.2264 26.8955 51.9998 26.8955C36.7732 26.8955 23.0988 23.5651 13.7568 18.2732"
      stroke="url(#paint4_linear_362_3580)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M52 1V26.8951" stroke="url(#paint5_linear_362_3580)" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M90.2427 85.7275C80.9007 80.4356 67.2264 77.1052 51.9998 77.1052C36.7732 77.1052 23.0988 80.4356 13.7568 85.7275"
      stroke="url(#paint6_linear_362_3580)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M52 103V77.1052" stroke="url(#paint7_linear_362_3580)" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M1 52.0002H26.5564" stroke="url(#paint8_linear_362_3580)" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M77.458 52.0002H103" stroke="url(#paint9_linear_362_3580)" strokeWidth="2" strokeMiterlimit="10" />
    <defs>
      <linearGradient
        id="paint0_linear_362_3580"
        x1="53"
        y1="52.5208"
        x2="52.0259"
        y2="52.5206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_362_3580"
        x1="53"
        y1="63.9876"
        x2="52.0263"
        y2="63.9667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_362_3580"
        x1="103"
        y1="97.3481"
        x2="3.64249"
        y2="97.0771"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_362_3580"
        x1="77.5"
        y1="97.3481"
        x2="27.821"
        y2="97.2803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_362_3580"
        x1="90.2427"
        y1="26.4177"
        x2="15.7813"
        y2="24.6167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_362_3580"
        x1="53"
        y1="25.4603"
        x2="52.0259"
        y2="25.4602"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_362_3580"
        x1="90.2427"
        y1="85.2498"
        x2="15.7813"
        y2="83.4487"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_362_3580"
        x1="53"
        y1="101.565"
        x2="52.0259"
        y2="101.565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_362_3580"
        x1="26.5564"
        y1="52.9448"
        x2="1.78221"
        y2="51.2184"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_362_3580"
        x1="103"
        y1="52.9448"
        x2="78.2396"
        y2="51.2203"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
    </defs>
  </svg>
);

export default OfflineIcon;
