import React, { useMemo } from "react";
import { useAccount } from "wagmi";
import { Trans, useTranslation } from "react-i18next";
import { ethers } from "ethers";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking } from "@src/services/eth/EthHooks";
import { getCurrentDateMiliseconds } from "@src/utils/getCurrentDate";

import StageCard from "@src/components/common/StageCard/StageCard";

import {
  StakingBefore,
  StakingNotConnected,
  StakingConnectedNotAssigned,
  StakingConnectedAssigned,
  StakingFinished
} from "./variants";

import "./StakingStage.scss";

const StakingStage = () => {
  const { isConnected } = useAccount();
  const { instancesData } = useStakingsData();
  const { data: assignedStaking } = useAssignedStaking();

  const { t } = useTranslation("staking");

  const cardActive = useMemo(() => {
    return instancesData?.[0].status === "earn";
  }, [instancesData]);

  const getAssignedStakingStatus = () => {
    return instancesData?.filter((item) => item.addr === assignedStaking)[0]?.status;
  };

  const content = useMemo(() => {
    // instancesDate not exist render default variant
    if (!instancesData) {
      return <StakingBefore />;
    }
    // staking stage before
    else if (getCurrentDateMiliseconds() < instancesData?.[0].stages.subscribeStageTo) {
      return <StakingBefore />;
    }
    // staking stage started & notConnected
    else if (!isConnected) {
      return <StakingNotConnected />;
    }
    // staking stage started & connected & notAssigned
    else if (assignedStaking === ethers.constants.AddressZero) {
      return <StakingConnectedNotAssigned />;
    }
    // staking stage started & connected & assigned
    else if (getAssignedStakingStatus() === "earn" || instancesData?.[0].status === "earn") {
      return <StakingConnectedAssigned />;
    }
    // staking stage ended
    else {
      return <StakingFinished />;
    }
  }, [instancesData, isConnected, assignedStaking]);

  return (
    <StageCard id="staking-stage" className="staking-stage" active={cardActive}>
      <div className="staking-stage-wrapper">
        <div className="staking-stage-info">
          <h1>
            <Trans
              t={t}
              i18nKey="stakingStage.info.title"
              components={{ br: <br /> }}
              values={{
                monthsFirstStaking: instancesData?.[0].months ?? 0,
                monthsSecondStaking: instancesData?.[1].months ?? 0
              }}
            />
          </h1>
          <p className="p2">{t("stakingStage.info.description")}</p>
        </div>

        <div className="staking-stage-content">{content}</div>
      </div>
    </StageCard>
  );
};

export default StakingStage;
