import { useModals } from "@contexts/ModalsContext";

import MetamaskUnavailable from "./MetamaskUnavailable/MetamaskUnavailable";
import ConfirmTxModal from "./ConfirmTxModal/ConfirmTxModal";
import LoadingTxModal from "./LoadingTxModal/LoadingTxModal";
import SuccessTxModal from "./SuccessTxModal/SuccessTxModal";
import ErrorTxModal from "./ErrorTxModal/ErrorTxModal";

const GlobalModals = () => {
  const { activeModal } = useModals();

  if (!activeModal) return <></>;

  const { type, object } = activeModal;

  if (type === "metamaskUnavailable") return <MetamaskUnavailable />;
  if (type === "confirmTxModal") return <ConfirmTxModal {...object} />;
  if (type === "loadingTxModal") return <LoadingTxModal {...object} />;
  if (type === "successTxModal") return <SuccessTxModal {...object} />;
  if (type === "errorTxModal") return <ErrorTxModal {...object} />;

  return <></>;
};

export default GlobalModals;
