import React, { useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { config } from "@src/services/eth/EthProvider";
import { useConnect, useSwitchNetwork } from "@src/services/eth/EthHooks";
import { useModals } from "@src/contexts/ModalsContext";

import Button from "../Button/Button";
import "./ConncectMetamask.scss";

interface ConncectMetamaskProps {
  fullSize?: boolean;
  freeWidth?: boolean;
  uppercase?: boolean;
  className?: string;
}

const ConncectMetamask = ({
  fullSize = false,
  freeWidth = false,
  uppercase = true,
  className = ""
}: ConncectMetamaskProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const network = useNetwork();
  const { status } = useAccount();
  const { connectAsync } = useConnect();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { openModal } = useModals();

  const { t: commonT } = useTranslation("common");

  const handleSwitchNetwork = () => {
    if (network?.chain?.id !== config.network.id) {
      switchNetworkAsync?.();
    }
  };

  const handleConnect = () => {
    setLoading(true);
    connectAsync()
      .then(handleSwitchNetwork)
      .catch((err) => {
        if ((err = "Connector not found")) return openModal("metamaskUnavailable");
        else console.error(err);
      })

      .finally(() => setLoading(false));
  };

  const buttonLoading = loading || status === "connecting";
  const classes = classNames("connect-metamask", className);

  return (
    <Button
      title={commonT("connectMetamask")}
      fullSize={fullSize}
      freeWidth={freeWidth}
      uppercase={uppercase}
      loading={buttonLoading}
      disabled={status === "connected"}
      onClick={handleConnect}
      className={classes}
    >
      {commonT("connectMetamask")}
    </Button>
  );
};

export default ConncectMetamask;
