const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;

type UNIT = "MONTHS" | "DAYS" | "HOURS" | "MINUTES" | "SECONDS";

export const convertMiliseconds = (ms: number, unit: UNIT = "MONTHS") => {
  switch (unit) {
    case "SECONDS":
      return Math.floor(ms / SECOND);
    case "MINUTES":
      return Math.floor(ms / MINUTE);
    case "HOURS":
      return Math.floor(ms / HOUR);
    case "DAYS":
      return Math.floor(ms / DAY);
    case "MONTHS":
      return Math.floor(ms / MONTH);
    default:
      return Math.floor(ms / MONTH);
  }
};
