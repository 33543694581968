import React from "react";

import { PrelaunchDateProps } from "../Prelaunch.types";
import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";

const PrelaunchFinished = ({ startDate, endDate }: PrelaunchDateProps) => {
  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar startDate={startDate} endDate={endDate} />
    </Stack>
  );
};

export default PrelaunchFinished;
