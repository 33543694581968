import React, { HTMLAttributeAnchorTarget, ReactNode } from "react";
import classNames from "classnames";

import Group from "@src/components/arrangement/Group/Group";

import "./Link.scss";

type LinkColorVariant = "primary" | "secondary";
type LinkSizes = "normal" | "small";

interface LinkProps {
  variant?: LinkColorVariant;
  size?: LinkSizes;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  follow?: boolean;
  title?: string;
  disabled?: boolean;
  image?: ReactNode;
  className?: string;
  children: ReactNode;
}

const Link = ({
  variant = "primary",
  size = "normal",
  href,
  target = "_self",
  follow = false,
  title,
  disabled = false,
  image,
  className = "",
  children
}: LinkProps) => {
  const rel = follow ? "dofollow" : `noopener noreferrer nofollow`;
  const classes = classNames("link", { disabled: disabled }, `link-${variant}`, `link-${size}`, className);

  return (
    <a href={href} target={target} rel={rel} title={title} className={classes}>
      <Group colGap={8}>
        {children}
        {image && image}
      </Group>
    </a>
  );
};

export default Link;
