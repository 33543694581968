import React, { ReactNode } from "react";
import classNames from "classnames";
import "./SocialIcon";

interface SocialIconProps {
  href: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

const SocialIcon = ({ href, title, disabled, className, children }: SocialIconProps) => {
  const classes = classNames("social-icon", { disabled: disabled }, className);

  return (
    <a href={href} target="_blank" rel="noopener noreferrer nofollow" title={title} className={classes}>
      {children}
    </a>
  );
};

export default SocialIcon;
