import { useTranslation } from "react-i18next";
import { useAccount, useFeeData } from "wagmi";
import { ethers } from "ethers";

import { useStakedBalance, useTokenBalance } from "@src/services/eth/EthHooks";
import { TxCommand, TxHandler, TxHandlerContext } from "@src/services/eth/txHandlers/TxHandler";
import { StakeTxHandler } from "@src/services/eth/txHandlers/StakeTxHandler";
import { UnstakeTxHandler } from "@src/services/eth/txHandlers/UnstakeTxHandler";
import { ClaimTxHandler } from "@src/services/eth/txHandlers/ClaimTxHandler";
import { useModals } from "@src/contexts/ModalsContext";

export const useTransaction = () => {
  const { openModal, closeModal } = useModals();
  const { t } = useTranslation("modals");
  const { t: commonT } = useTranslation("common");
  const { t: blockchainErrorsT } = useTranslation("blockchainErrors");
  const { data: feeData } = useFeeData();
  const { data: tokenBalance } = useTokenBalance();
  const { data: stakedBalance } = useStakedBalance();
  const { address } = useAccount();

  const startTransaction = async (command: TxCommand) => {
    matchTxHandler(command).onConfirm();
  };

  const handleTx = async (handler: TxHandler) => {
    handler.onLoading();

    try {
      await handler.sendTx();
      handler.onSuccess();
    } catch (e: any) {
      console.error(e);
      handler.onError(e);
    }
  };

  const createTxHandlerContext = (): TxHandlerContext => ({
    tokenBalance,
    stakedBalance,
    feeData,
    openModal,
    closeModal,
    handleTx,
    startTransaction,
    t,
    commonT,
    blockchainErrorsT,
    userAddress: address ?? ethers.constants.AddressZero
  });

  const matchTxHandler = (command: TxCommand): TxHandler => {
    const ctx = createTxHandlerContext();

    switch (command.type) {
      case "stake":
        return new StakeTxHandler(ctx, command);
      case "unstake":
        return new UnstakeTxHandler(ctx, command);
      case "claim":
        return new ClaimTxHandler(ctx, command);
      default:
        throw new Error("Invalid command");
    }
  };

  return { startTransaction };
};
