import React from "react";
import classNames from "classnames";

import { CURRENCY_SKEY } from "@src/constants/constants";
import { SKEY_DECIMALS, SKEY_TOTAL_SUPPLY } from "@src/config";
import { AmountInputProps } from "./AmountInput.types";

import "./AmountInput.scss";

const AmountInput = ({
  id,
  inputRef,
  name,
  placeholder,
  defaultValue,
  value,
  error,
  disabled,
  readOnly,
  autoComplete,
  currency = CURRENCY_SKEY,
  onClick,
  onChange,
  onBlur,
  className
}: AmountInputProps) => {
  const classes = classNames("amount-input", className);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dots = e.target.value.split("").filter((i) => i === ".").length;
    const integer = e.target.value.split(".")[0] ?? "";
    const fractional = e.target.value.split(".")[1] ?? "";

    if (dots > 1) {
      e.target.value = value ?? "";
    }

    if (fractional.length > SKEY_DECIMALS) {
      e.target.value = value ?? "";
    }

    if (integer.length > SKEY_TOTAL_SUPPLY.toString().length) {
      e.target.value = value ?? "";
    }

    if (value === "" && e.target.value === ".") {
      e.target.value = "0.";
    }

    e.target.value = e.target.value.replace(/[^\d|.]/g, "");

    onChange?.(e);
  };

  return (
    <div className={classes}>
      <input
        type="text"
        id={id}
        ref={inputRef}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={autoComplete}
        onClick={onClick}
        onChange={handleChange}
        onBlur={onBlur}
        className="amount-input-input"
      />
      <span className="amount-input-border" />
      <span className="amount-input-currency">{currency}</span>
    </div>
  );
};

export default AmountInput;
