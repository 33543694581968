import React from "react";
import { Navigate } from "react-router-dom";

import { IChildren } from "src/types/IChildren";
import { APP_STAGE } from "@src/config";
import { PATHS } from "@src/router/paths/paths";

const DevModeGuard = ({ children }: IChildren) => {
  if (APP_STAGE === "dev") {
    return <>{children}</>;
  }

  return <Navigate to={PATHS.main.path} />;
};

export default DevModeGuard;
