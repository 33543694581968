import React from "react";
import { useTranslation } from "react-i18next";

import { ETH_TOKEN_ADDRESS } from "@src/config";
import { faqQuestions } from "@src/data/faqQuestions";
import { skeyTokenData } from "@src/data/skeyToken";
import addTokenToMetamask from "@src/utils/addTokenToMetamask";

import Stack from "@src/components/arrangement/Stack/Stack";
import Accordion from "@src/components/common/Accordion/Accordion";
import Button from "@src/components/dataEntry/Buttons/Button/Button";

import "./Faq.scss";

const Faq = () => {
  const { t: commonT } = useTranslation("common");
  const { t: faqT } = useTranslation("faq");
  const faqQuestionsWithoutButtons = faqQuestions.slice(1);

  const handleAddTokenToMetamask = () => addTokenToMetamask(skeyTokenData);

  return (
    <Stack alignItems="center" justifyContent="center" rowGap={48} fullWidth className="staking-faq">
      <h2>{commonT("faq")}</h2>

      <Accordion>
        <Accordion.Tab>
          <Accordion.Summary>{faqT(faqQuestions[0].question as any)}</Accordion.Summary>
          <Accordion.Details>
            <Stack rowGap={10} className="faq-answer-content">
              <p className="p0 lh-4 text-justify">
                {faqT(faqQuestions[0].answer as any, { token: ETH_TOKEN_ADDRESS })}
              </p>
              <Button variant="text" onClick={handleAddTokenToMetamask} className="add-token-btn">
                {faqT(faqQuestions[0].buttonLabel as any)}
              </Button>
            </Stack>
          </Accordion.Details>
        </Accordion.Tab>

        {faqQuestionsWithoutButtons?.map(({ question, answer }, index) => {
          return (
            <Accordion.Tab key={index}>
              <Accordion.Summary>{faqT(question as any)}</Accordion.Summary>
              <Accordion.Details>
                <Stack className="faq-answer-content">
                  <p className="p0 lh-4 text-justify">{faqT(answer as any)}</p>
                </Stack>
              </Accordion.Details>
            </Accordion.Tab>
          );
        })}
      </Accordion>
    </Stack>
  );
};

export default Faq;
