import React, { useContext, useState } from "react";
import classNames from "classnames";

import useElementSize from "@src/hooks/useElementSize";

import CollapseTarget from "./common/CollapseTarget";
import CollapseContent from "./common/CollapseContent";

import "./Collapse.scss";

interface CollapseProps {
  expandedDefault?: boolean;
  children: React.ReactNode;
  className?: string;
}

interface ContextValue {
  tagRef: (node: HTMLDivElement | null) => void;
  isExpanded: boolean;
  contentHeight: number;
  handleToggleExpanded: () => void;
  getContentHeight: (isExpanded: boolean) => number;
}

const CollapseContext = React.createContext(null as any);

const Collapse = ({ expandedDefault, children, className }: CollapseProps) => {
  const [isExpanded, setExpanded] = useState(expandedDefault ?? false);
  const [contentHeight, setContentHeight] = React.useState<number>(0);

  const [tagRef, { height: elementHeight }] = useElementSize();

  const handleToggleExpanded = () => setExpanded((prevState) => !prevState);

  const getContentHeight = () => {
    return isExpanded ? elementHeight : 0;
  };
  React.useEffect(() => {
    const height = getContentHeight();
    setContentHeight(height);
  }, [isExpanded, elementHeight]);

  const classes = classNames("collapse", className);

  const contextValue: ContextValue = {
    tagRef,
    isExpanded,
    contentHeight,
    handleToggleExpanded,
    getContentHeight
  };

  return (
    <CollapseContext.Provider value={contextValue}>
      <div className={classes}>{children}</div>
    </CollapseContext.Provider>
  );
};

export const useCollapseComp = (): ContextValue => useContext(CollapseContext);

Collapse.Target = CollapseTarget;
Collapse.Content = CollapseContent;

export default Collapse;
