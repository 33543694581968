import React from "react";

interface Icon {
  width?: number;
  height?: number;
  className?: string;
}

const SkeyNetworkPin = ({ width = 32, height = 32, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="mask0_125_2891"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="28"
      height="28"
    >
      <circle cx="16.0001" cy="16.0001" r="13.7143" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_125_2891)">
      <g filter="url(#filter0_dddd_125_2891)">
        <rect
          x="29.6138"
          y="2.36023"
          width="27.21"
          height="27.3563"
          rx="13.6308"
          transform="rotate(90 29.6138 2.36023)"
          fill="white"
        />
        <rect
          x="29.1138"
          y="2.86023"
          width="26.21"
          height="26.3563"
          rx="13.1308"
          transform="rotate(90 29.1138 2.86023)"
          stroke="url(#paint0_linear_125_2891)"
        />
      </g>
      <g filter="url(#filter1_ddddii_125_2891)">
        <path
          d="M12.4029 12.4972H12.7005L12.703 12.4948H14.5889L10.5668 16.5168C10.0705 17.0131 9.90947 17.515 9.91986 17.9426C9.93054 18.3823 10.1242 18.8053 10.444 19.1252C10.7639 19.445 11.1868 19.6387 11.6265 19.6494C12.054 19.6599 12.5557 19.4989 13.0518 19.0028L18.9294 13.1253C19.659 12.3956 20.5045 12.0681 21.3304 12.0882C22.1443 12.108 22.8849 12.4642 23.4233 13.0027C23.9618 13.5412 24.3178 14.2819 24.3376 15.0957C24.3577 15.9216 24.0302 16.7671 23.3007 17.4966L20.1891 20.6083H18.3032L22.3578 16.5537C22.8539 16.0576 23.0149 15.5558 23.0045 15.1282C22.9938 14.6885 22.8002 14.2655 22.4803 13.9456C22.1605 13.6257 21.7376 13.432 21.298 13.4213C20.8704 13.4109 20.3686 13.5719 19.8723 14.0682L13.9948 19.9457C13.2653 20.6752 12.4198 21.0027 11.5939 20.9826C10.7801 20.9627 10.0395 20.6065 9.50105 20.068C8.96262 19.5295 8.60652 18.7889 8.58674 17.975C8.56667 17.1491 8.89426 16.3035 9.62387 15.5739L10.8331 14.3647L12.0597 13.1381L10.325 13.1381L8.72072 13.1381L9.36325 12.4972L12.4029 12.4972Z"
          fill="#FAFAFC"
          stroke="url(#paint1_linear_125_2891)"
          strokeWidth="1.43708"
        />
        <path
          d="M12.4029 12.4972H12.7005L12.703 12.4948H14.5889L10.5668 16.5168C10.0705 17.0131 9.90947 17.515 9.91986 17.9426C9.93054 18.3823 10.1242 18.8053 10.444 19.1252C10.7639 19.445 11.1868 19.6387 11.6265 19.6494C12.054 19.6599 12.5557 19.4989 13.0518 19.0028L18.9294 13.1253C19.659 12.3956 20.5045 12.0681 21.3304 12.0882C22.1443 12.108 22.8849 12.4642 23.4233 13.0027C23.9618 13.5412 24.3178 14.2819 24.3376 15.0957C24.3577 15.9216 24.0302 16.7671 23.3007 17.4966L20.1891 20.6083H18.3032L22.3578 16.5537C22.8539 16.0576 23.0149 15.5558 23.0045 15.1282C22.9938 14.6885 22.8002 14.2655 22.4803 13.9456C22.1605 13.6257 21.7376 13.432 21.298 13.4213C20.8704 13.4109 20.3686 13.5719 19.8723 14.0682L13.9948 19.9457C13.2653 20.6752 12.4198 21.0027 11.5939 20.9826C10.7801 20.9627 10.0395 20.6065 9.50105 20.068C8.96262 19.5295 8.60652 18.7889 8.58674 17.975C8.56667 17.1491 8.89426 16.3035 9.62387 15.5739L10.8331 14.3647L12.0597 13.1381L10.325 13.1381L8.72072 13.1381L9.36325 12.4972L12.4029 12.4972Z"
          fill="#FAFAFC"
          stroke="url(#paint2_linear_125_2891)"
          strokeWidth="1.43708"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dddd_125_2891"
        x="-160.786"
        y="-38.4005"
        width="353.442"
        height="353.296"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.54755" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_125_2891" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="20.3804" />
        <feGaussianBlur stdDeviation="20.3804" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_125_2891" result="effect2_dropShadow_125_2891" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="81.5215" />
        <feGaussianBlur stdDeviation="61.1411" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_125_2891" result="effect3_dropShadow_125_2891" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="122.282" />
        <feGaussianBlur stdDeviation="81.5215" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect3_dropShadow_125_2891" result="effect4_dropShadow_125_2891" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_125_2891" result="shape" />
      </filter>
      <filter
        id="filter1_ddddii_125_2891"
        x="-3.79524"
        y="0.590712"
        width="41.786"
        height="34.045"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.59269" dy="3.59269" />
        <feGaussianBlur stdDeviation="4.67049" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_125_2891" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3.59269" dy="-3.59269" />
        <feGaussianBlur stdDeviation="3.59269" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_125_2891" result="effect2_dropShadow_125_2891" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.59269" dy="-3.59269" />
        <feGaussianBlur stdDeviation="3.59269" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_125_2891" result="effect3_dropShadow_125_2891" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3.59269" dy="3.59269" />
        <feGaussianBlur stdDeviation="3.59269" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="effect3_dropShadow_125_2891" result="effect4_dropShadow_125_2891" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_125_2891" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.718538" dy="-0.718538" />
        <feGaussianBlur stdDeviation="0.718538" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect5_innerShadow_125_2891" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.718538" dy="0.718538" />
        <feGaussianBlur stdDeviation="0.718538" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect5_innerShadow_125_2891" result="effect6_innerShadow_125_2891" />
      </filter>
      <linearGradient
        id="paint0_linear_125_2891"
        x1="56.8237"
        y1="28.2007"
        x2="30.3187"
        y2="28.1288"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="0.446418" stopColor="#5C44EC" />
        <stop offset="1" stopColor="#BB5DEE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_125_2891"
        x1="25.057"
        y1="21.1294"
        x2="7.45133"
        y2="21.0454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="0.446418" stopColor="#5C44EC" />
        <stop offset="1" stopColor="#BB5DEE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_125_2891"
        x1="25.057"
        y1="21.1294"
        x2="7.45133"
        y2="21.0454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="0.446418" stopColor="#5C44EC" />
        <stop offset="1" stopColor="#BB5DEE" />
      </linearGradient>
    </defs>
  </svg>
);

export default SkeyNetworkPin;
