import React from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { convertMiliseconds } from "@src/utils/convertMiliseconds";

import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";

const ClaimingBeforeNotAssigned = () => {
  const { instancesData } = useStakingsData();

  const { t } = useTranslation("staking");
  const { t: commonT } = useTranslation("common");

  const claimingDurationMs =
    (instancesData && instancesData?.[0].stages.claimStageTo - instancesData?.[0].stages.earnStageTo) || 0;
  const beforeInfo = `${convertMiliseconds(claimingDurationMs)} ${commonT("months")}`;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar beforeInfo={beforeInfo} />
      <h2 className="gradient-text">{t("claimingStage.beforeNotAssigned.title")}</h2>
    </Stack>
  );
};

export default ClaimingBeforeNotAssigned;
