import React, { useMemo } from "react";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking } from "@src/services/eth/EthHooks";
import { BlockchainAddress } from "@src/types/Blockchain.types";

import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";

const ClaimingFinshedAssigned = () => {
  const { instancesData, getInstanceData } = useStakingsData();
  const { data: assignedStaking } = useAssignedStaking();

  const assignedInstance = useMemo(() => {
    return getInstanceData(assignedStaking as BlockchainAddress);
  }, [instancesData, getInstanceData, assignedStaking]);

  const startDate = useMemo(() => {
    return new Date(assignedInstance?.stages.earnStageTo || 0);
  }, [assignedInstance]);

  const endDate = useMemo(() => {
    return new Date(assignedInstance?.stages.claimStageTo || 0);
  }, [assignedInstance]);

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar startDate={startDate} endDate={endDate} />
    </Stack>
  );
};

export default ClaimingFinshedAssigned;
