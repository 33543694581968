import { BigNumber } from "ethers";
import { CustomTypeOptions, useTranslation } from "react-i18next";

import { BlockchainAddress } from "@src/types/Blockchain.types";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useStakedBalance, useTokenBalance } from "@src/services/eth/EthHooks";
import { convertBigNumber } from "@src/utils/convertBigNumber";
import { numberWithSpaces } from "@src/utils/numberWithSpaces";

export type AmountInputError = keyof CustomTypeOptions["resources"]["formErrors"];

export const useValidateAmount = (value: BigNumber, stakingAddress?: BlockchainAddress): string | null => {
  const stakings = useStakingsData();
  const tokenBalance = useTokenBalance();
  const { data: stakedBalance } = useStakedBalance();

  const { t: errorsT } = useTranslation("formErrors");

  if (!value) return errorsT("invalidNumber");

  if (value.eq(0)) return errorsT("zeroValue");

  if (tokenBalance.isSuccess && value.gt(tokenBalance.data!.value)) {
    return errorsT("lowTokenBalance");
  }

  if (!stakingAddress || !stakings.getInstanceData(stakingAddress)) {
    return null;
  }

  const stakingData = stakings.getInstanceData(stakingAddress)!;

  if (value.add(stakedBalance || BigNumber.from(0)).gt(stakingData.data.maxUserStake.bigNumber)) {
    return errorsT("exceedsMaxUserStake", { maxUserStake: stakingData.data.maxUserStake.number });
  }

  const available = stakingData.data.maxTotalStake.bigNumber.sub(stakingData.data.currentTotalDeposit.bigNumber);

  // TODO test if it works
  if (value.gt(available)) {
    const availableTokens = numberWithSpaces(convertBigNumber(available));
    return errorsT("notEnoughTokensOnStaking", { availableTokens });
  }

  return null;
};
