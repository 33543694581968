import React from "react";

interface Icon {
  width?: number;
  height?: number;
  className?: string;
}

const NotFoundIcon = ({ width = 118, height = 104, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 118 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M66.1541 103H37.4648"
      stroke="url(#paint0_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.2188 5.06421H60.1709C63.473 5.06421 68.0876 6.9693 70.4302 9.29774L85.5439 24.3409C87.8864 26.6693 89.7915 31.2698 89.7915 34.5719V55.6408"
      stroke="url(#paint1_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M65.9102 6.42969V24.6926C65.9102 26.7526 67.5933 28.4297 69.6316 28.4297H87.9102"
      stroke="url(#paint2_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.4644 103V89.0575L49.9392 71.7282L45.6915 55.415L59.8457 41.3879L41.2181 5.06418"
      stroke="url(#paint3_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8528 98.9358H6.99751C3.69535 98.9358 1 96.2404 1 92.9382V6.99751C1 3.69535 3.69535 1 6.99751 1H27.8971"
      stroke="url(#paint4_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M26.8525 98.9358V84.9933L37.9726 70.3735L33.725 52.7056L46.5384 38.6785L27.8968 1"
      stroke="url(#paint5_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.473 103C116.675 103 117.578 101.448 116.477 99.5426L91.7816 56.7839C90.6809 54.8788 88.8746 54.8788 87.788 56.7839L63.1065 99.5426C62.0058 101.448 62.9089 103 65.1103 103H114.473Z"
      stroke="url(#paint6_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M89.7773 85.1203V73.4217"
      stroke="url(#paint7_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.7773 94.4905V94.6034"
      stroke="url(#paint8_linear_361_3555)"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_361_3555"
        x1="66.1541"
        y1="103.945"
        x2="38.3779"
        y2="101.772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_361_3555"
        x1="89.7915"
        y1="52.8383"
        x2="42.4771"
        y2="52.7144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_361_3555"
        x1="87.9102"
        y1="27.2106"
        x2="66.4801"
        y2="27.1522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_361_3555"
        x1="59.8457"
        y1="97.5732"
        x2="38.044"
        y2="97.5596"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_361_3555"
        x1="27.8971"
        y1="93.509"
        x2="1.69664"
        y2="93.4894"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_361_3555"
        x1="46.5384"
        y1="93.509"
        x2="27.3624"
        y2="93.4985"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_361_3555"
        x1="116.946"
        y1="100.36"
        x2="64.0443"
        y2="100.195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_361_3555"
        x1="90.7773"
        y1="84.4721"
        x2="89.8032"
        y2="84.4719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_361_3555"
        x1="90.7773"
        y1="94.5972"
        x2="89.8038"
        y2="94.5736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
    </defs>
  </svg>
);

export default NotFoundIcon;
