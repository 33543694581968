import { STAKING_TERMS } from "@src/constants/constants";

interface StakingAgreements {
  id: string;
  content: string;
  link?: {
    label: string;
    url: string;
  };
  required: boolean;
}

export const stakingAgreements: StakingAgreements[] = [
  {
    id: "agreements1",
    content: "agreements.one.content",
    link: {
      label: "agreements.one.link",
      url: STAKING_TERMS
    },
    required: true
  }
];
