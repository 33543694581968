import React, { useState } from "react";
import { usePopper } from "react-popper";
import classNames from "classnames";

import useVisible from "@hooks/useVisible";

import { TooltipProps } from "./Tooltip.types";
import "./Tooltip.scss";

const Tooltip = ({
  tip,
  placement = "top",
  width = 0,
  hide = false,
  textCenter = false,
  fullWidth = false,
  displayAfterClick = false,
  className = "",
  children
}: TooltipProps) => {
  const [tooltipRef, setTooltipRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const { isVisible, setVisibilityTrue, setVisibilityFalse, visibilityToggle } = useVisible(false);

  const handleClick = () => displayAfterClick && visibilityToggle();

  const { styles, attributes } = usePopper(tooltipRef, popperRef, {
    placement: placement,
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 12]
        }
      }
    ]
  });

  const tipWidth = width > 0 ? `${width}px` : "auto";
  const showTooltip = !hide && isVisible;

  const tooltipWrapperClass = classNames("tooltip-wrapper", { "full-width": fullWidth }, className);
  const tipClass = classNames("tooltip-tip", { "text-center": textCenter });

  return (
    <div
      ref={setTooltipRef}
      onMouseEnter={setVisibilityTrue}
      onMouseLeave={setVisibilityFalse}
      onMouseDown={setVisibilityFalse}
      onClick={handleClick}
      className={tooltipWrapperClass}
    >
      {showTooltip && (
        <div
          ref={setPopperRef}
          role="tooltip"
          className={tipClass}
          style={{ ...styles.popper, width: tipWidth }}
          {...attributes.popper}
        >
          {tip}
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
