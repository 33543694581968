export interface IToken {
  address: string;
  symbol: string;
  decimals: number;
  image: string;
}

const addTokenToMetamask = async (token: IToken) => {
  const ethereum = (window as any).ethereum;

  if (!ethereum) {
    return console.error("Metamask is not installed");
  }

  try {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          ...token
        }
      }
    });
  } catch (error) {
    console.error("Could't add token to metamask", error);
  }
};

export default addTokenToMetamask;
