import React, { useEffect, useState } from "react";
import { getCurrentDateMiliseconds } from "@src/utils/getCurrentDate";

// Convert to millisecond
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const getReturnTimeLeftValues = (timeLeft: number) => {
  let days, hours, minutes, seconds: number;

  if (timeLeft <= SECOND) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  // calculate time left
  days = Math.floor(timeLeft / DAY);
  hours = Math.floor((timeLeft % DAY) / HOUR);
  minutes = Math.floor((timeLeft % HOUR) / MINUTE);
  seconds = Math.floor((timeLeft % MINUTE) / SECOND);

  return { days, hours, minutes, seconds };
};

const useCountdownTimer = (targetDate: Date) => {
  const currentDateMilliseconds = getCurrentDateMiliseconds();
  const targetDateMilliseconds = targetDate.getTime();

  const [timeLeft, setTimeLeft] = useState<number>(targetDateMilliseconds - currentDateMilliseconds);

  useEffect(() => {
    setTimeLeft(targetDateMilliseconds - currentDateMilliseconds);
  }, [targetDate, currentDateMilliseconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft <= SECOND) return;
      else {
        const currDate = getCurrentDateMiliseconds();
        setTimeLeft(targetDateMilliseconds - currDate);
      }
    }, SECOND);

    return () => clearInterval(interval);
  }, [targetDate, timeLeft]);

  return getReturnTimeLeftValues(timeLeft);
};

export default useCountdownTimer;
