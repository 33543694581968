import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAssignedStaking, useStakedBalance, useTokenBalance } from "@src/services/eth/EthHooks";
import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useTransaction } from "@src/hooks/useTransaction";
import { useValidateAmount } from "@src/hooks/useValidateAmount";

import { CURRENCY_SKEY } from "@src/constants/constants";
import { PrelaunchDateProps } from "../Prelaunch.types";
import { BlockchainAddress } from "@src/types/Blockchain.types";
import { numberWithSpaces } from "@src/utils/numberWithSpaces";
import { convertBigNumber } from "@src/utils/convertBigNumber";

import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import Collapse from "@src/components/dataDisplay/Collapse/Collapse";
import Button from "@src/components/dataEntry/Buttons/Button/Button";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";
import StakingAmountInput from "@src/components/common/StakingAmountInput/StakingAmountInput";
import StakingInfoCard from "@src/components/common/StakingInfoCard/StakingInfoCard";
import CheckedCardIcon from "@src/assets/CheckedCardIcon";
import SkeyNetworkPin from "@src/assets/SkeyNetworkPin";
import CollapseDepositMore from "@src/components/common/CollapseDepositMore/CollapseDepositMore";
import { parseToBigNumber } from "@src/utils/parseToBigNumber";
import { BigNumber } from "ethers";

const PrelaunchConnectedAssigned = ({ startDate, endDate }: PrelaunchDateProps) => {
  const [amountValue, setAmountValue] = useState<string>("");
  const [formTouched, setFormTouched] = useState<boolean>(false);

  const { data: tokenBalance } = useTokenBalance();
  const { data: stakedBalance } = useStakedBalance();
  const { data: assignedStaking } = useAssignedStaking();
  const { instancesData, getInstanceData } = useStakingsData();
  const { startTransaction } = useTransaction();

  const { t } = useTranslation("staking");

  const assignedInstance = useMemo(() => {
    return getInstanceData(assignedStaking as BlockchainAddress);
  }, [instancesData, getInstanceData, assignedStaking]);

  const amountValueBigNumber = parseToBigNumber(amountValue);
  const amountError = useValidateAmount(amountValueBigNumber || BigNumber.from(0), assignedInstance?.addr);

  const maxBalance = Number(tokenBalance?.formatted ?? 0);
  const formattedStakedBalance = stakedBalance ? numberWithSpaces(convertBigNumber(stakedBalance)) : 0;

  const handleMoreDeposit = () => {
    if (!assignedInstance || amountError || !amountValue) return;

    startTransaction({ type: "stake", args: { value: amountValue, data: assignedInstance } });
  };

  const handleWithdraw = () => {
    startTransaction({ type: "unstake", args: {} });
  };

  const depositButtonDisabled = !assignedInstance?.addr || !!amountError;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar startDate={startDate} endDate={endDate} />

      <Group colGap={32} fullWidth>
        <StakingInfoCard
          title={t("prelaunchStage.connectedAssigned.stakingDuration.label")}
          tip={t("prelaunchStage.connectedAssigned.stakingDuration.tip")}
          tipWidth={220}
          active
        >
          <p className="p2 bold">{assignedInstance?.months || 0} months</p>
          <p className="p0">{assignedInstance?.data.earningPercent.percent}</p>
          <CheckedCardIcon checked />
        </StakingInfoCard>

        <StakingInfoCard
          title={t("prelaunchStage.connectedAssigned.stakingAmount.label")}
          tip={t("prelaunchStage.connectedAssigned.stakingAmount.tip")}
          tipWidth={220}
          active
        >
          <p className="p2 bold text-center">
            {formattedStakedBalance} {CURRENCY_SKEY}
          </p>
          <SkeyNetworkPin width={48} height={48} />
        </StakingInfoCard>
      </Group>

      <Collapse>
        <Collapse.Target>
          <CollapseDepositMore title={t("prelaunchStage.connectedAssigned.depositMore")} />
        </Collapse.Target>

        <Collapse.Content>
          <div className="pt-4 pb-4">
            <StakingAmountInput
              maxBalance={maxBalance}
              amountValue={amountValue}
              onChange={setAmountValue}
              onTouched={setFormTouched}
              error={formTouched && !!amountError}
            />

            {formTouched && amountError && <p className="p0 form-error pt-2">{formTouched && amountError}</p>}

            <Button
              title={t("prelaunchStage.depositButton.title")}
              fullSize
              uppercase
              onClick={handleMoreDeposit}
              disabled={depositButtonDisabled}
              className="mt-4 test-btn"
            >
              {t("prelaunchStage.depositButton.label")}
            </Button>
          </div>
        </Collapse.Content>
      </Collapse>

      <p className="p0 withdraw-description">{t("prelaunchStage.connectedAssigned.withdraw.info")}</p>

      <Button variant="secondary" fullSize onClick={handleWithdraw}>
        {t("prelaunchStage.connectedAssigned.withdraw.button")}
      </Button>
    </Stack>
  );
};

export default PrelaunchConnectedAssigned;
