import React, { ChangeEvent, useState } from "react";
import { nanoid } from "nanoid";

import { SKEY_DECIMALS } from "@src/config";
import { roundValue } from "@src/utils/roundValue";

import PercentageButton from "@src/components/dataEntry/Buttons/PercentageButton/PercentageButton";
import AmountInput from "../AmountInput/AmountInput";

import { Percentages, percentages } from "./percentages";
import "./StakingAmountInput.scss";

interface StakingAmountInputProps {
  amountValue: string;
  title?: string;
  maxBalance: number;
  error?: boolean;
  onChange: (value: string) => void;
  onTouched?(touched: boolean): void;
}

const StakingAmountInput = ({
  amountValue,
  title,
  maxBalance,
  error,
  onChange,
  onTouched
}: StakingAmountInputProps) => {
  const [activeButton, setActiveButton] = useState<Percentages | null>(null);

  const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setActiveButton(null);
    onChange(event.target.value);
    onTouched?.(true);
  };

  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const percentageValue = Number(event.currentTarget.value);
    setActiveButton(percentageValue as Percentages);

    const amount = maxBalance * percentageValue;
    const formattedAmount = roundValue(amount, SKEY_DECIMALS);
    onChange(formattedAmount.toString());
    onTouched?.(true);
  };

  const checkIsActiveButton = (value: Percentages) => value === activeButton;

  return (
    <div className="staking-amount">
      <p className="p0 bold">{title || "Staking amount"}</p>

      <div className="staking-amount-container">
        <AmountInput
          name="staking-amount"
          placeholder="0"
          value={amountValue}
          onChange={handleChangeAmount}
          error={error}
        />

        <div className="input-buttons">
          {percentages.map(({ value, label }) => (
            <PercentageButton
              key={nanoid()}
              value={value}
              onClick={handleClickButton}
              fullWidth
              active={checkIsActiveButton(value)}
            >
              {label}
            </PercentageButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StakingAmountInput;
