import React, { useEffect, useMemo, useState } from "react";
import { BigNumber } from "ethers";
import { useTranslation } from "react-i18next";

import { BlockchainAddress } from "@src/types/Blockchain.types";
import { PrelaunchDateProps } from "../Prelaunch.types";

import { useTokenBalance } from "@src/services/eth/EthHooks";
import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useTransaction } from "@src/hooks/useTransaction";
import { useValidateAmount } from "@src/hooks/useValidateAmount";

import { parseToBigNumber } from "@src/utils/parseToBigNumber";
import { stakingAgreements } from "@src/data/stakingAgreements";

import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import Button from "@src/components/dataEntry/Buttons/Button/Button";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";
import StakingVariantCard from "@src/components/common/StakingVariantCard/StakingVariantCard";
import StakingAmountInput from "@src/components/common/StakingAmountInput/StakingAmountInput";
import Checkbox from "@src/components/dataEntry/Checkbox/Checkbox";
import Link from "@src/components/navigation/Link/Link";

const PrelaunchConnectedNotAssigned = ({ startDate, endDate }: PrelaunchDateProps) => {
  const [selectedInstance, setSelectedInstance] = useState<BlockchainAddress>();
  const [amountValue, setAmountValue] = useState<string>("0");
  const [formTouched, setFormTouched] = useState<boolean>(false);

  const [agreementsChecked, setAgreementsChecked] = useState<{ id: string; required: boolean; checked: boolean }[]>([
    {
      id: "agreements1",
      required: false,
      checked: false
    }
  ]);

  const { data: tokenBalance } = useTokenBalance();
  const { instancesData, getInstanceData } = useStakingsData();
  const { startTransaction } = useTransaction();

  const amountValueBigNumber = parseToBigNumber(amountValue);
  const amountError = useValidateAmount(amountValueBigNumber || BigNumber.from(0), selectedInstance);
  const maxBalance = Number(tokenBalance?.formatted ?? 0);

  const { t } = useTranslation("staking");
  const { t: commonT } = useTranslation("common");
  const { t: agreementsT } = useTranslation("agreements");

  React.useEffect(() => {
    if (!selectedInstance && instancesData) {
      setSelectedInstance(instancesData[0].addr);
    }
  }, [selectedInstance, instancesData]);

  useEffect(() => {
    const initialState = stakingAgreements.map((agreement) => {
      const test = { id: agreement.id, required: agreement.required, checked: false };
      return test;
    });

    setAgreementsChecked(initialState);
  }, []);

  const handleDeposit = () => {
    setFormTouched(true);
    if (!selectedInstance || !getInstanceData(selectedInstance) || amountError || !amountValue) return;

    startTransaction({ type: "stake", args: { value: amountValue, data: getInstanceData(selectedInstance)! } });
  };

  const handleChangeAgreementsChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    agreementsChecked?.forEach((agreement, index) => {
      if (agreement.id === name) {
        const updatedAgreement = { ...agreement, checked };

        const agreements = [
          ...agreementsChecked.slice(0, index),
          updatedAgreement,
          ...agreementsChecked.slice(index + 1)
        ];
        return setAgreementsChecked(agreements);
      }
    });
  };

  const isRequiredAgreementsChecked = useMemo(() => {
    const requiredAgreements = agreementsChecked.filter((agreements) => agreements.required === true);
    return requiredAgreements.every((agreement) => agreement.checked === true);
  }, [agreementsChecked]);

  const depositButtonDisabled = !selectedInstance || !!amountError || !isRequiredAgreementsChecked;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar startDate={startDate} endDate={endDate} />

      <Stack rowGap={12} fullWidth>
        <p className="p0 bold">{t("prelaunchStage.connectedNotAssigned.stakingDuration")}</p>

        <Group colGap={32} fullWidth alignItems="center">
          {instancesData?.map(({ addr, data, months }) => {
            return (
              <StakingVariantCard
                key={addr}
                percentage={data.earningPercent.percent}
                months={months}
                address={addr}
                onClick={(_, address) => setSelectedInstance(address)}
                active={selectedInstance === addr}
                tip={commonT("durationTip")}
              />
            );
          })}
        </Group>
      </Stack>

      <Stack rowGap={16} fullWidth>
        <StakingAmountInput
          maxBalance={maxBalance}
          amountValue={amountValue}
          onChange={setAmountValue}
          onTouched={setFormTouched}
          error={formTouched && !!amountError}
        />

        {formTouched && amountError && <p className="p0 form-error">{formTouched && amountError}</p>}
      </Stack>

      <Stack rowGap={10}>
        <p className="p0 bold">{agreementsT("title")}</p>
        {stakingAgreements.map(({ id, content, link, required }) => {
          const label = !link ? (
            agreementsT(content as any)
          ) : (
            <>
              {agreementsT(content as any)}
              <Link href={link.url} target="_blank">
                {agreementsT(link.label as any)}
              </Link>
            </>
          );

          return (
            <Checkbox
              key={id}
              name={id}
              label={label}
              required={required}
              labelTextSize="small"
              onChange={handleChangeAgreementsChecked}
            />
          );
        })}
      </Stack>

      <Button
        title={t("prelaunchStage.depositButton.title")}
        fullSize
        uppercase
        onClick={handleDeposit}
        disabled={depositButtonDisabled}
      >
        {t("prelaunchStage.depositButton.label")}
      </Button>
    </Stack>
  );
};

export default PrelaunchConnectedNotAssigned;
