import React from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";

import Backdrop from "@components/ux/Backdrop/Backdrop";
import Portal from "@components/overlay/Portal/Portal";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Buttons/Button/Button";
import WalletAddress from "@src/components/common/WalletAddress/WalletAddress";
import Group from "@src/components/arrangement/Group/Group";

import "./ConfirmTxModal.scss";

export interface ConfirmTxModalProps {
  onContinue: () => void;
  onCancel: () => void;
  continueText?: string;
  cancelText?: string;
  title?: string;
  textRows?: {
    key: string;
    value: string;
  }[];
}

const ConfirmTxModal: React.FC<ConfirmTxModalProps> = (props) => {
  const { address } = useAccount();
  const { t } = useTranslation("modals");

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={props.onCancel}>
          <Stack alignItems="center" className="confirm-tx-modal">
            <Group fullWidth justifyContent="space-between">
              <h2>{props.title ?? t("txModalDefault.title")}</h2>
              <WalletAddress address={address ?? ""} />
            </Group>
            <Stack fullWidth className="mt-6 mb-5">
              {(props.textRows ?? []).map(({ key, value }) => (
                <Group key={key} justifyContent="flex-start" className="mb-2">
                  <p className="row-key">{key}</p>
                  <p className="bold">{value}</p>
                </Group>
              ))}
            </Stack>
            <Stack fullWidth rowGap={24}>
              <Button variant="primary" onClick={props.onContinue} fullSize>
                {props.continueText ?? t("txModalDefault.continue")}
              </Button>
              <Button variant="secondary" onClick={props.onCancel} fullSize>
                {props.cancelText ?? t("txModalDefault.cancel")}
              </Button>
            </Stack>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default ConfirmTxModal;
