import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";
import ConncectMetamask from "@src/components/dataEntry/Buttons/ConncectMetamask/ConncectMetamask";

import { PrelaunchDateProps } from "../Prelaunch.types";

const PrelaunchNotConnected = ({ startDate, endDate }: PrelaunchDateProps) => {
  const { t: commonT } = useTranslation("common");

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar startDate={startDate} endDate={endDate} />
      <h2>{commonT("notConnected")}</h2>
      <ConncectMetamask fullSize />
    </Stack>
  );
};

export default PrelaunchNotConnected;
