import React from "react";
import classNames from "classnames";

import { CURRENCY_SKEY } from "@src/constants/constants";
import { numberWithSpaces } from "@src/utils/numberWithSpaces";
import { roundValue } from "@src/utils/roundValue";

import "./StakingProgress.scss";

interface StakingProgressProps {
  currentTotalDeposit: number;
  maxTotalStake: number;
}

const StakingProgress = ({ currentTotalDeposit, maxTotalStake }: StakingProgressProps) => {
  const percentageProgress = (currentTotalDeposit / maxTotalStake) * 100;

  const isCompleted = currentTotalDeposit >= maxTotalStake;

  const classess = classNames("staking-progress", { "staking-progress-completed": isCompleted });

  return (
    <div className={classess}>
      <div className="staking-progress-bar">
        <div className="current-point " style={{ "--progress-width": `${percentageProgress}%` } as React.CSSProperties}>
          <p className="p1">{roundValue(percentageProgress)}%</p>
        </div>
        <div className="line-inactive" />
        <div className="line-active" style={{ "--progress-width": `${percentageProgress}%` } as React.CSSProperties} />
      </div>

      <div className="staking-progress-info mt-1">
        <p className="p1">0 {CURRENCY_SKEY}</p>

        <p className="p1">
          {numberWithSpaces(maxTotalStake)} {CURRENCY_SKEY}
        </p>
      </div>
    </div>
  );
};

export default StakingProgress;
