export type Percentages = (typeof percentages)[number]["value"];

export const percentages = [
  {
    value: 0.25,
    label: "25%"
  },
  {
    value: 0.5,
    label: "50%"
  },
  {
    value: 0.75,
    label: "75%"
  },
  {
    value: 1,
    label: "Max"
  }
] as const;
