import React from "react";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

import { LINKED_IN, SKEY_MAIL, SKEY_SUPPORT } from "@src/constants/constants";
import { socialMedia, telegramChannels } from "@src/data/socialMedia";

import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Link from "@src/components/navigation/Link/Link";
import SocialIcon from "@src/components/navigation/SocialIcon/SocialIcon";
import SocialIconWithDropdown from "@src/components/navigation/SocialIconWithDropdown/SocialIconWithDropdown";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation("layout");

  return (
    <Stack rowGap={34} className="footer">
      <Group colGap={6}>
        <p className="p0 white-1">{t("footer.ourTeam.description")} </p>
        <Link
          variant="secondary"
          href={LINKED_IN}
          target="_blank"
          title={t("footer.ourTeam.link.title")}
          className="fs-5"
        >
          {t("footer.ourTeam.link.label")}
        </Link>
      </Group>

      <Group colGap={24} alignItems="flex-start" fullWidth className="footer-row">
        <Stack rowGap={24} className="footer-row-socials">
          <p className="p0 white-1">{t("footer.followUs.title")}</p>

          <Group colGap={15} rowGap={15} alignItems="center" fullWidth wrap="wrap">
            {socialMedia.map(({ title, logoPath, url }) => (
              <SocialIcon key={nanoid()} href={url} title={t(title as any)}>
                <img src={logoPath} alt="social media button" width="31" height="30" className="op-sm-50" />
              </SocialIcon>
            ))}

            <SocialIconWithDropdown
              title={t("footer.followUs.telegramChannels.title")}
              logoPath="/images/socialMedia/telegram.svg"
              links={telegramChannels}
            />
          </Group>
        </Stack>

        <Stack rowGap={24} className="footer-row-legal-disclaimer">
          <p className="p0 white-1">{t("footer.legalDisclaimer.title")}</p>

          <p className="p1 white-1">
            {t("footer.legalDisclaimer.subtitle")} <br />
            Skey Network UAB <br />
            st. Lvivo g. 105 <br />
            2nd floor <br />
            08104 Vilnius, Lithuania
          </p>
        </Stack>

        <Stack rowGap={24} className="footer-row-contact">
          <p className="p0 white-1">{t("footer.contact.title")}</p>

          <div>
            <p className="p1 white-1">{t("footer.contact.information")}</p>
            <Link variant="secondary" size="small" href={`mailto:${SKEY_MAIL}`}>
              {SKEY_MAIL}
            </Link>
          </div>
          <div>
            <p className="p1 white-1">{t("footer.contact.integration")}</p>
            <Link variant="secondary" size="small" href={`mailto:${SKEY_SUPPORT}`}>
              {SKEY_SUPPORT}
            </Link>
          </div>
        </Stack>

        <Stack rowGap={24} className="footer-row-project-reporting">
          <p className="p0 white-1">{t("footer.projectReporting.title")}</p>

          <img src="/images/europe-flag.png" alt="europe flag" width={54} height={36} />

          <p className="p1 white-1">{t("footer.projectReporting.description")}</p>
        </Stack>
      </Group>
    </Stack>
  );
};

export default Footer;
