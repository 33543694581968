import React from "react";

interface Icon {
  width?: number;
  height?: number;
  className?: string;
}

const CollapseArrow = ({ width = 24, height = 24, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M6 9L12 15L18 9" stroke="#5C44EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CollapseArrow;
