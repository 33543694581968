import React, { useMemo } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { Trans, useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking } from "@src/services/eth/EthHooks";
import { numberWithSpaces } from "@src/utils/numberWithSpaces";

import StageCard from "@src/components/common/StageCard/StageCard";

import {
  PrelaunchBefore,
  PrelaunchNotConnected,
  PrelaunchConnectedNotAssigned,
  PrelaunchConnectedAssigned,
  PrelaunchFinished
} from "./variants";

import "./PrelaunchStage.scss";

const PrelaunchStage = () => {
  const { isConnected } = useAccount();
  const { data: assignedStaking } = useAssignedStaking();
  const { instancesData } = useStakingsData();

  const { t } = useTranslation("staking");

  const cardActive = useMemo(() => {
    return instancesData?.[0].status === "subscribe";
  }, [instancesData]);

  const startDate = useMemo(() => {
    return new Date(instancesData?.[0].stages.subscribeStageFrom || 0);
  }, [instancesData]);

  const endDate = useMemo(() => {
    return new Date(instancesData?.[0].stages.subscribeStageTo || 0);
  }, [instancesData]);

  const content = useMemo(() => {
    // instancesDate not exist render default variant
    if (!instancesData) {
      return <PrelaunchBefore />;
    }
    // pre-launch stage before
    else if (instancesData?.[0].status === "pending") {
      return <PrelaunchBefore />;
    }
    // pre-launch stage started
    else if (instancesData?.[0].status === "subscribe") {
      // pre-launch stage started & notConnected
      if (!isConnected) return <PrelaunchNotConnected startDate={startDate} endDate={endDate} />;
      else {
        // pre-launch stage started & connected & assigned
        if (assignedStaking !== ethers.constants.AddressZero || !assignedStaking) {
          return <PrelaunchConnectedAssigned startDate={startDate} endDate={endDate} />;
        }
        // pre-launch stage started & connected & notAssigned
        else {
          return <PrelaunchConnectedNotAssigned startDate={startDate} endDate={endDate} />;
        }
      }
    }
    // pre-launch stage ended
    else {
      return <PrelaunchFinished startDate={startDate} endDate={endDate} />;
    }
  }, [instancesData, isConnected, assignedStaking]);

  return (
    <StageCard id="prelaunch-stage" className="prelaunch-stage" active={cardActive}>
      <div className="prelaunch-stage-wrapper">
        <div className="prelaunch-stage-info">
          <h1>
            <Trans t={t} i18nKey="prelaunchStage.info.title" components={{ br: <br /> }} />
          </h1>
          <p className="p2">
            {t("prelaunchStage.info.description", {
              maxDeposit: numberWithSpaces(instancesData?.[0].data.maxUserStake.number || 0),
              monthsFirstStaking: instancesData?.[0].months || 0,
              monthsSecondStaking: instancesData?.[1].months || 0
            })}
          </p>
        </div>

        <div className="prelaunch-stage-content">{content}</div>
      </div>
    </StageCard>
  );
};

export default PrelaunchStage;
