import React from "react";
import { ethers } from "ethers";
import { useAccount, useNetwork, useFeeData } from "wagmi";

import { StakingDataStruct, decodeError, getStakingStatus, getTxFee } from "@src/services/eth/EthUtils";
import { config } from "@src/services/eth/EthProvider";
import * as EthHooks from "@src/services/eth/EthHooks";
import { useTransaction } from "@src/hooks/useTransaction";

import Container from "@src/components/arrangement/Container/Container";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import Button from "@src/components/dataEntry/Buttons/Button/Button";
import Collapse from "@src/components/dataDisplay/Collapse/Collapse";

import "./Debug.scss";

(window as any).decodeError = decodeError;

const Debug: React.FC = () => {
  const { status } = useAccount();
  const network = useNetwork();
  const { data: feeData } = useFeeData();
  const { connect } = EthHooks.useConnect();
  const { data: ethBalance } = EthHooks.useETHBalance();
  const { data: tokenBalance } = EthHooks.useTokenBalance();
  const { data: instances } = EthHooks.useStakingInstances();
  const { data: allowance } = EthHooks.useCurrentAllowance(instances?.[0]?.addr);
  const { data: assignedStaking } = EthHooks.useAssignedStaking();
  const { switchNetworkAsync } = EthHooks.useSwitchNetwork();
  const { write: sendApproveTx } = EthHooks.useApproveTx(instances?.[0]?.addr);
  const { write: sendStakeTx, tx: stakeTx } = EthHooks.useStakeTx(
    0,
    ethers.utils.parseUnits("10000", 8),
    allowance?.gte(ethers.utils.parseUnits("10000", 8)) ?? false
  );
  const { data: stakedBalance } = EthHooks.useStakedBalance();
  const { startTransaction } = useTransaction();

  React.useEffect(() => {
    const elem = document.getElementById("patimex") as HTMLAudioElement;

    const func = () => {
      if (elem.paused) elem.play();
      else elem.pause();
    };

    window.addEventListener("click", func);

    return () => window.removeEventListener("click", func);
  }, []);

  return (
    <Container size="xxl" fullSize className="mt-5 mb-10">
      <Stack rowGap={32} fullWidth className="debug mt-5">
        <h1 className="debug-title">Debug view</h1>
        <Group colGap={56} alignItems="flex-start" justifyContent="center" fullSize>
          <Stack rowGap={8}>
            <p className="p0">
              <span className="bold">Connection status:</span> {status}
            </p>

            <Button onClick={connect} disabled={status !== "disconnected"}>
              Connect
            </Button>
          </Stack>

          <Stack rowGap={8}>
            <p className="p0">
              <span className="bold">Switch network to:</span> {config.network.name}
            </p>

            <Button onClick={switchNetworkAsync} disabled={network?.chain?.id === config.network.id}>
              Switch network
            </Button>
          </Stack>
        </Group>

        <Stack rowGap={8}>
          <p className="p0">
            <span className="bold">Current network:</span> {network?.chain?.name}
          </p>
          <p className="p0">
            <span className="bold">ETH balance:</span> {ethBalance?.formatted}
          </p>
          <p className="p0">
            <span className="bold">Token balance:</span> {tokenBalance?.formatted}
          </p>
          <p className="p0">
            <span className="bold">Staking instances:</span> {instances?.map((inst) => inst.addr).join(", ")}
          </p>
          <p className="p0">
            <span className="bold">Staking #1 allowance:</span> {allowance && ethers.utils.formatUnits(allowance, 8)}
          </p>
          <p className="p0">
            <span className="bold">Assigned staking:</span> {assignedStaking}
          </p>
          <p>
            <span className="bold">Staking balance: </span>
            {stakedBalance && ethers.utils.formatUnits(stakedBalance, 8)}
          </p>
          <p>
            <span className="bold">Staking #1 stage: </span>
            {getStakingStatus(instances?.[0]?.data as StakingDataStruct)}
          </p>
        </Stack>

        <Group colGap={56} alignItems="flex-start" justifyContent="center" fullWidth>
          <Button onClick={sendApproveTx}>Enable Token</Button>
          <span>Stake tx fee: {ethers.utils.formatEther(getTxFee(stakeTx, feeData))}</span>
          <Button onClick={sendStakeTx}>Stake 10000</Button>
        </Group>

        <Group colGap={56} alignItems="flex-start" justifyContent="center" fullWidth>
          <Button onClick={() => startTransaction({ type: "unstake", args: {} })}>Withdraw</Button>
        </Group>

        <audio id="patimex">
          <source src="/assets/patimex.mp3" type="audio/mpeg" />
        </audio>

        <Collapse>
          <Collapse.Target>
            <h3 className="collapse-btn bold">click me :)</h3>
          </Collapse.Target>
          <Collapse.Content>
            <img src="/assets/coding-humor.gif" alt="" className="mt-3 mb-3" />
          </Collapse.Content>
        </Collapse>
      </Stack>
    </Container>
  );
};

export default Debug;
