import React from "react";
import { useCollapseComp } from "../Collapse";

interface CollapseContentProps {
  children: React.ReactNode;
}

const CollapseContent = ({ children }: CollapseContentProps) => {
  const { tagRef, contentHeight, isExpanded } = useCollapseComp();

  return (
    <div style={{ height: contentHeight }} className="collapse-content">
      <div ref={tagRef}>{children}</div>
    </div>
  );
};

export default CollapseContent;
