import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { datesDiffInSeconds } from "@src/utils/datesDiffInSeconds";
import { formatDate } from "@src/utils/formatDateTime";
import { getCurrentDate } from "@src/utils/getCurrentDate";

import "./DaysProgressBar.scss";

interface DaysProgressBarProps {
  title?: string;
  startDate?: Date;
  endDate?: Date;
  beforeInfo?: string;
  className?: string;
}

const DaysProgressBar = ({ title, startDate, endDate, beforeInfo, className }: DaysProgressBarProps) => {
  const { t } = useTranslation("common");

  // const today = new Date();
  const today = getCurrentDate();
  const datesExist = startDate && endDate;

  const currentDateDifference = datesExist ? datesDiffInSeconds(startDate, today) : { seconds: 0, days: 0 };
  const datesDifference = datesExist ? datesDiffInSeconds(startDate, endDate) : { seconds: 0, days: 0 };
  const progressValue = (currentDateDifference.seconds / datesDifference.seconds) * 100;

  const leftDays = datesDifference.days - currentDateDifference.days;
  const leftSeconds = datesDifference.seconds - currentDateDifference.seconds;

  const isCompleted = datesExist && leftSeconds <= 0;

  const formattedStartDate = datesExist ? formatDate(startDate) : "";
  const formattedEndDate = datesExist ? formatDate(endDate) : "";

  const leftDaysInfo = useMemo(() => {
    if (!datesExist) return null;
    else if (isCompleted) return t("completed");
    else return `${leftDays} ${t("daysLeft")}`;
  }, [isCompleted, leftDays]);

  const classess = classNames("days-progress-bar", { "days-progress-bar-completed": isCompleted }, className);

  return (
    <div className={classess}>
      {isCompleted ? (
        <div className="progress-bar-completed-icon">
          <img src="/images/completed-progress-icon.svg" alt="completed icon" width={48} height={48} />
        </div>
      ) : (
        <p className="p0 bold mb-2">{title || t("duration")}</p>
      )}

      <div className="days-progress-bar-info mb-1">
        {datesExist && (
          <p className="p0">
            {formattedStartDate} - {formattedEndDate}
          </p>
        )}
        <p className="p0">{datesExist ? leftDaysInfo : beforeInfo}</p>
      </div>

      <div className="progress-bar">
        <div className="line-inactive" />
        <div className="line-active" style={{ "--progress-width": `${progressValue}%` } as React.CSSProperties} />
      </div>
    </div>
  );
};

export default DaysProgressBar;
