import { Path } from "./Path";
import { ETH_AGGREGATOR_ADDRESS } from "@src/config";

export const PATHS = {
  main: new Path({
    path: "/"
  }),
  notFound: new Path({
    path: "/*"
  }),
  debug: new Path({
    path: "/debug"
  }),
  stakingInstances: new Path({
    path: `/${ETH_AGGREGATOR_ADDRESS}`
  })
} as const;
