import React from "react";

import { useAccordionTab } from "../AccordionTab/AccordionTab";

import { AccordionSummaryProps } from "../../Accordion.types";
import Group from "@src/components/arrangement/Group/Group";
import AccordionPlusIcon from "../AccordionPlusIcon/AccordionPlusIcon";

import "./AccordionSummary.scss";

const AccordionSummary = ({ children }: AccordionSummaryProps) => {
  const { variant, handleClick, handleHover, handleBlur } = useAccordionTab();

  return (
    <div
      role="button"
      onClick={handleClick}
      onMouseOver={handleHover}
      onMouseLeave={handleBlur}
      className="accordion-summary"
    >
      <Group justifyContent="space-between" fullWidth>
        <p className="p2 bold">{children}</p>
        <AccordionPlusIcon variant={variant} />
      </Group>
    </div>
  );
};

export default AccordionSummary;
