import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";

import Stack from "@src/components/arrangement/Stack/Stack";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";

const StakingConnectedNotAssigned = () => {
  const { instancesData } = useStakingsData();
  const { t } = useTranslation("staking");

  const monthsInfo = `${instancesData?.[0].months ?? 0} or ${instancesData?.[1].months ?? 0} months`;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar beforeInfo={monthsInfo} />

      <div>
        <h2 className="gradient-text">{t("stakingStage.connectedNotAssigned.title")}</h2>
        <p className="p0 mt-1">{t("stakingStage.connectedNotAssigned.description")}</p>
      </div>
    </Stack>
  );
};

export default StakingConnectedNotAssigned;
