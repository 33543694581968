import React from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";

import { useTokenBalance } from "@src/services/eth/EthHooks";
import { scrollToTop } from "@src/utils/scrollTo";

import Group from "@src/components/arrangement/Group/Group";
import SkeyNetworkLink from "@src/components/common/SkeyNetworkLink/SkeyNetworkLink";
import WalletAddress from "@src/components/common/WalletAddress/WalletAddress";
import WalletBalance from "@src/components/common/WalletBalance/WalletBalance";

import "./Navbar.scss";

const Navbar = () => {
  const { t } = useTranslation("layout");
  const { status, address } = useAccount();
  const { data: tokenBalance } = useTokenBalance();

  const balance = Number(tokenBalance?.formatted ?? 0);

  return (
    <div className="navbar">
      <button title={t("navbar.skeyStakingLogo")} onClick={scrollToTop}>
        <img src="/images/logos/skey-staking.svg" alt="Skey Staking logo" width={185} height={39} />
      </button>

      <Group colGap={40}>
        {status === "connected" && (
          <Group colGap={16}>
            <WalletBalance balance={balance} />
            <WalletAddress address={address} />
          </Group>
        )}

        <SkeyNetworkLink />
      </Group>
    </div>
  );
};

export default Navbar;
