import React from "react";
import { isMobile } from "react-device-detect";

import { IChildren } from "src/types/IChildren";
import UnavailableOnMobile from "./UnavailableOnMobile/UnavailableOnMobile";

const MobileDeviceGuard = ({ children }: IChildren) => {
  if (isMobile) {
    return <UnavailableOnMobile />;
  }
  return <>{children}</>;
};

export default MobileDeviceGuard;
