import React from "react";
import { useTranslation } from "react-i18next";

import { copyToClipboard } from "@utils/copyToClipboard";

import Backdrop from "@components/ux/Backdrop/Backdrop";
import Portal from "@components/overlay/Portal/Portal";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Buttons/Button/Button";

import MobileDevice from "@src/assets/MobileDevice";
import "./UnavailableOnMobile.scss";

const UnavailableOnMobile = () => {
  const { t } = useTranslation("guards");
  const currentUrl = window.location.href;

  const handleCopyUrl = () => copyToClipboard(currentUrl);

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack alignItems="center" className="unavailable-on-mobile-modal">
            <MobileDevice className="mb-4" />
            <h3 className="bold text-center mb-1">{t("unavailableOnMobile.title")}</h3>
            <p className="p0 text-center mb-5">{t("unavailableOnMobile.description")}</p>
            <Button onClick={handleCopyUrl} fullSize>
              {t("unavailableOnMobile.button")}
            </Button>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default UnavailableOnMobile;
