import { useEffect, useMemo } from "react";
import { useAccount, useNetwork } from "wagmi";

import { IChildren } from "@src/types/IChildren";
import { config } from "@src/services/eth/EthProvider";
import useVisible from "@src/hooks/useVisible";

import SwitchNetwork from "./SwitchNetwork/SwitchNetwork";

const BlockchainNetworkGuard = ({ children }: IChildren) => {
  const { isVisible, setVisibilityTrue, setVisibilityFalse } = useVisible();
  const { isConnected } = useAccount();
  const network = useNetwork();

  const correctNetwork = useMemo(() => {
    return network?.chain?.id === config.network.id;
  }, [network, config]);

  useEffect(() => {
    if (isConnected && !correctNetwork) setVisibilityTrue();
    else setVisibilityFalse();
  }, [isConnected, correctNetwork]);

  return (
    <>
      {children}
      {isVisible && <SwitchNetwork onClose={setVisibilityFalse} />}
    </>
  );
};

export default BlockchainNetworkGuard;
