import { useCallback, useState } from "react";

export const usePromise = <T, Y extends Array<any>>(func: (...args: Y) => Promise<T>) => {
  const [resolved, setResolved] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const call = useCallback(
    (...args: Y) => {
      reset();
      setLoading(true);

      func(...args)
        .then(setResolved)
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [func]
  );

  const reset = useCallback(() => {
    setResolved(null);
    setError(null);
    setLoading(false);
  }, []);

  return { resolved, error, loading, call, reset };
};
