import React from "react";
import classNames from "classnames";
import "./PercentageButton.scss";

interface PercentageButtonProps {
  children?: React.ReactNode;
  id?: string;
  name?: string;
  title?: string;
  value?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  active?: boolean;
  uppercase?: boolean;
  className?: string;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

const PercentageButton = ({
  children,
  id,
  name,
  title,
  value,
  disabled = false,
  fullWidth = false,
  active = false,
  uppercase = false,
  className = "",
  onClick = () => {}
}: PercentageButtonProps) => {
  const classes = classNames(
    "percentage-button",
    { "percentage-button--active": active },
    { "full-width": fullWidth },
    { "text-uppercase": uppercase },
    className
  );

  return (
    <button id={id} name={name} value={value} title={title} disabled={disabled} onClick={onClick} className={classes}>
      {children}
    </button>
  );
};

export default PercentageButton;
