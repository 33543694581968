import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import i18n from "@src/translations/i18n";

import "dayjs/locale/en";
import "dayjs/locale/pl";

dayjs.extend(utc);

export const formatDateTime = (date?: string | number | Date, locale?: "en" | "pl", template?: string) => {
  return dayjs(date)
    .locale(locale || i18n.language)
    .format(template || "DD MMMM YYYY HH:mm");
};

export const formatDate = (date?: string | number | Date, locale?: "en" | "pl", template?: string) => {
  return dayjs(date)
    .locale(locale || i18n.language)
    .format(template || "DD MMMM YYYY");
};

export const formatDateTimeUTC = (date?: string | number | Date, locale?: "en" | "pl", template?: string) => {
  const formattedDate = dayjs(date)
    .utc(true)
    .locale(locale || i18n.language)
    .format(template || "DD MMMM YYYY HH:mm A");

  return formattedDate + " (UTC)";
};
