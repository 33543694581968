import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";

import { StakingDataStruct, StakingStatus, getStakingStatus } from "@services/eth/EthUtils";
import { useStakingInstances } from "@src/services/eth/EthHooks";

import { IContext } from "@src/types/IContext.types";
import { BlockchainAddress } from "@src/types/Blockchain.types";
import { UPDATE_STAKING_DATA_TIME } from "@src/config";
import { getCurrentDateMiliseconds } from "@src/utils/getCurrentDate";
import { convertEarningPercent } from "@src/utils/convertEarningPercent";
import { convertBigNumber } from "@src/utils/convertBigNumber";
import { convertMiliseconds } from "@src/utils/convertMiliseconds";

type NumberValue = {
  bigNumber: BigNumber;
  number: number;
};

export interface StakingData {
  index: number;
  addr: BlockchainAddress;
  token: BlockchainAddress;
  status: StakingStatus;
  months: number;
  stages: {
    subscribeStageFrom: number;
    subscribeStageTo: number;
    earnStageTo: number;
    claimStageTo: number;
  };
  data: {
    currentTotalDeposit: NumberValue;
    earningPercent: NumberValue & { percent: string };
    earningsQuota: NumberValue;
    maxTotalStake: NumberValue;
    maxUserStake: NumberValue;
    unusedQuota: NumberValue;
  };
}

interface ContextValue {
  dateNow: number;
  instancesData: StakingData[] | null;
  getInstanceData: (instanceAddr: BlockchainAddress) => StakingData | undefined;
}

const StakingsDataContext = React.createContext(null as any);

export const StakingsDataProvider = ({ children }: IContext) => {
  const [instancesData, setInstancesData] = useState<StakingData[] | null>(null);
  const { data: stakingInstances } = useStakingInstances();

  const dateNow = getCurrentDateMiliseconds();

  const getInstancesData = () => {
    const data = stakingInstances?.map(({ addr, data }, index) => {
      return {
        index,
        addr,
        token: data.token,
        status: getStakingStatus(data as StakingDataStruct),
        months: convertMiliseconds(data.earnStageTo * 1000 - data.subscribeStageFrom * 1000, "MONTHS"),
        stages: {
          subscribeStageFrom: data.subscribeStageFrom * 1000,
          subscribeStageTo: data.subscribeStageTo * 1000,
          earnStageTo: data.earnStageTo * 1000,
          claimStageTo: data.claimStageTo * 1000
        },
        data: {
          currentTotalDeposit: {
            bigNumber: data.currentTotalDeposit,
            number: convertBigNumber(data.currentTotalDeposit)
          },
          earningPercent: {
            bigNumber: data.earningPercent,
            number: convertBigNumber(data.earningPercent, 12),
            percent: `${convertEarningPercent(data.earningPercent)}%`
          },
          earningsQuota: {
            bigNumber: data.earningsQuota,
            number: convertBigNumber(data.earningsQuota)
          },
          maxTotalStake: {
            bigNumber: data.maxTotalStake,
            number: convertBigNumber(data.maxTotalStake)
          },
          maxUserStake: {
            bigNumber: data.maxUserStake,
            number: convertBigNumber(data.maxUserStake)
          },
          unusedQuota: {
            bigNumber: data.unusedQuota,
            number: convertBigNumber(data.unusedQuota)
          }
        }
      };
    }) as StakingData[];

    return data;
  };

  const updateInstancesStatus = () => {
    instancesData?.forEach((instance, index) => {
      const currStatus = getStakingStatus(stakingInstances?.[instance.index].data as StakingDataStruct);
      if (instance.status !== currStatus) {
        const updatedInstance: StakingData = { ...instance, status: currStatus };

        const instances = [...instancesData.slice(0, index), updatedInstance, ...instancesData.slice(index + 1)];
        return setInstancesData(instances);
      }
    });
  };

  const getInstanceData = useCallback(
    (instanceAddr: BlockchainAddress) => {
      return instancesData?.filter((item) => item.addr === instanceAddr)[0];
    },
    [instancesData]
  );

  useEffect(() => {
    setInstancesData(null);
    const data = getInstancesData();
    setInstancesData(data);
  }, [stakingInstances]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateInstancesStatus();
    }, UPDATE_STAKING_DATA_TIME);

    return () => clearInterval(interval);
  });

  const contextValue: ContextValue = {
    dateNow,
    instancesData,
    getInstanceData
  };

  return <StakingsDataContext.Provider value={contextValue}>{children}</StakingsDataContext.Provider>;
};

export const useStakingsData = (): ContextValue => React.useContext(StakingsDataContext);
