import React, { useMemo } from "react";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { Trans, useTranslation } from "react-i18next";

import { BlockchainAddress } from "@src/types/Blockchain.types";
import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking } from "@src/services/eth/EthHooks";
import { getCurrentDateMiliseconds } from "@src/utils/getCurrentDate";

import StageCard from "@src/components/common/StageCard/StageCard";

import {
  ClaimingBeforeNotAssigned,
  ClaimingBeforeAssigned,
  ClaimingAssigned,
  ClaimingFinshedAssigned
} from "./variants";

import "./ClaimingStage.scss";

const ClaimingStage = () => {
  const { isConnected } = useAccount();
  const { data: assignedStaking } = useAssignedStaking();
  const { instancesData, getInstanceData } = useStakingsData();

  const { t } = useTranslation("staking");

  const assignedInstance = useMemo(() => {
    return getInstanceData(assignedStaking as BlockchainAddress);
  }, [instancesData, getInstanceData, assignedStaking]);

  const cardActive = useMemo(() => {
    return assignedInstance?.status === "claim";
  }, [assignedInstance]);

  const content = useMemo(() => {
    // instancesDate not exist render default variant
    if (!instancesData || !isConnected) {
      return <ClaimingBeforeNotAssigned />;
    }
    // not assigned
    else if (assignedStaking === ethers.constants.AddressZero || !assignedStaking) {
      return <ClaimingBeforeNotAssigned />;
    }
    // assigned
    else if (assignedInstance) {
      // before claiming assigned instance
      if (getCurrentDateMiliseconds() < assignedInstance?.stages.earnStageTo) {
        return <ClaimingBeforeAssigned />;
      }
      // claiming assigned instance started
      else if (assignedInstance.status === "claim") {
        return <ClaimingAssigned />;
      }
      // claiming assigned instance ended
      else {
        return <ClaimingFinshedAssigned />;
      }
    }
  }, [instancesData, isConnected, assignedInstance]);

  return (
    <StageCard id="claiming-stage" className="claiming-stage" active={cardActive} withoutArrow>
      <div className="claiming-stage-wrapper">
        <div className="claiming-stage-info">
          <h1>
            <Trans t={t} i18nKey="claimingStage.info.title" components={{ br: <br /> }} />
          </h1>
          <p className="p2">{t("claimingStage.info.description")}</p>
        </div>

        <div className="claiming-stage-content">{content}</div>
      </div>
    </StageCard>
  );
};

export default ClaimingStage;
