import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking, useStakedBalance } from "@src/services/eth/EthHooks";
import { useTransaction } from "@src/hooks/useTransaction";

import { CURRENCY_SKEY } from "@src/constants/constants";
import { BlockchainAddress } from "@src/types/Blockchain.types";
import { calculateTotalRewards } from "@src/utils/calculateEarnings";
import { convertBigNumber } from "@src/utils/convertBigNumber";
import { numberWithSpaces } from "@src/utils/numberWithSpaces";

import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Buttons/Button/Button";
import StakingInfoCard from "@src/components/common/StakingInfoCard/StakingInfoCard";
import DaysProgressBar from "@src/components/dataDisplay/DaysProgressBar/DaysProgressBar";
import SkeyNetworkPin from "@src/assets/SkeyNetworkPin";
import Tooltip from "@src/components/dataDisplay/Tooltip/Tooltip";

const ClaimingAssigned = () => {
  const { data: assignedStaking } = useAssignedStaking();
  const { data: stakedBalance } = useStakedBalance();
  const { instancesData, getInstanceData } = useStakingsData();
  const { startTransaction } = useTransaction();

  const { t: commonT } = useTranslation("common");

  const assignedInstance = useMemo(() => {
    return getInstanceData(assignedStaking as BlockchainAddress);
  }, [instancesData, getInstanceData, assignedStaking]);

  const startDate = useMemo(() => {
    return new Date(assignedInstance?.stages.earnStageTo || 0);
  }, [assignedInstance]);

  const endDate = useMemo(() => {
    return new Date(assignedInstance?.stages.claimStageTo || 0);
  }, [assignedInstance]);

  const totalStaked = stakedBalance ? convertBigNumber(stakedBalance) : 0;
  const formattedTotalStaked = numberWithSpaces(totalStaked);

  const stakingBouns = assignedInstance?.data.earningPercent.percent || "0%";

  const stakingRewards =
    stakedBalance && assignedInstance
      ? calculateTotalRewards(stakedBalance, assignedInstance?.data.earningPercent.bigNumber)
      : 0;
  const formattedStakingRewards = numberWithSpaces(stakingRewards);

  const handleClaim = () => {
    if (!assignedInstance) return;

    startTransaction({ type: "claim", args: { data: assignedInstance } });
  };

  const claimButtonDisabled = totalStaked <= 0;

  return (
    <Stack rowGap={48} className="mt-3" fullWidth>
      <DaysProgressBar startDate={startDate} endDate={endDate} />

      <div className="claiming-stage-info-cards">
        <StakingInfoCard title={commonT("totalStaked.title")} tip={commonT("totalStaked.tip")} tipWidth={220}>
          <p className="p2 bold text-center">
            {formattedTotalStaked} {CURRENCY_SKEY}
          </p>
        </StakingInfoCard>

        <StakingInfoCard title={commonT("stakingBonus.title")} tip={commonT("stakingBonus.tip")} tipWidth={220}>
          <p className="p2 bold text-center">{stakingBouns}</p>
        </StakingInfoCard>

        <StakingInfoCard title={commonT("stakingDuration.title")} tip={commonT("stakingDuration.tip")} tipWidth={220}>
          <p className="p2 bold text-center">
            {assignedInstance?.months || 0} {commonT("months")}
          </p>
        </StakingInfoCard>

        <StakingInfoCard
          title={commonT("stakingRewards.title")}
          tip={commonT("stakingRewards.tip")}
          tipWidth={220}
          active
        >
          <p className="p2 bold text-center mt-2">{formattedStakingRewards}</p>
          <SkeyNetworkPin width={48} height={48} />
        </StakingInfoCard>
      </div>

      <Tooltip tip={commonT("claimButtonDisabled")} hide={!claimButtonDisabled} fullWidth>
        <Button fullSize onClick={handleClaim} disabled={claimButtonDisabled}>
          {commonT("claim")}
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default ClaimingAssigned;
