import React, { useEffect } from "react";

import { IChildren } from "@src/types/IChildren";
import useVisible from "@src/hooks/useVisible";

import UnsupportedBrowser from "./UnsupportedBrowser/UnsupportedBrowser";

const BrowserGuard = ({ children }: IChildren) => {
  const { isVisible, setVisibilityTrue, setVisibilityFalse } = useVisible();
  const userAgent = navigator.userAgent;

  const supportedBrowser =
    userAgent.indexOf("Firefox") === -1 ||
    userAgent.indexOf("Chrome") === -1 ||
    userAgent.indexOf("Brave") === -1 ||
    userAgent.indexOf("Edge") === -1 ||
    userAgent.indexOf("Opera") === -1 ||
    userAgent.indexOf("OPR") === -1;

  useEffect(() => {
    if (!supportedBrowser) setVisibilityTrue();
    else setVisibilityFalse();
  }, [userAgent]);

  return (
    <>
      {children}
      {isVisible && <UnsupportedBrowser onClose={setVisibilityFalse} />}
    </>
  );
};

export default BrowserGuard;
