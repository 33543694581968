import { BigNumber, ethers } from "ethers";
import { TFunction } from "react-i18next";
import { FetchBalanceResult, FetchFeeDataResult } from "wagmi/actions";

import type { ContextValue as ModalContext } from "@src/contexts/ModalsContext";
import { BlockchainAddress } from "@src/types/Blockchain.types";
import { decodeError } from "../EthUtils";
import { SKEY_DECIMALS } from "@src/config";

import type { ConfirmTxModalProps } from "@src/components/modals/ConfirmTxModal/ConfirmTxModal";
import type { LoadingTxModalProps } from "@src/components/modals/LoadingTxModal/LoadingTxModal";
import type { SuccessTxModalProps } from "@src/components/modals/SuccessTxModal/SuccessTxModal";
import type { ErrorTxModalProps } from "@src/components/modals/ErrorTxModal/ErrorTxModal";

import type { StakeTxCommand } from "./StakeTxHandler";
import type { UnstakeTxCommand } from "./UnstakeTxHandler";
import type { ClaimTxCommand } from "./ClaimTxHandler";
import { ConnectorNotFoundError } from "wagmi";

export interface TxHandlerContext {
  tokenBalance?: FetchBalanceResult;
  stakedBalance?: BigNumber;
  feeData?: FetchFeeDataResult;
  userAddress: BlockchainAddress;
  openModal: ModalContext["openModal"];
  closeModal: ModalContext["closeModal"];
  handleTx: (handler: TxHandler) => void;
  startTransaction: (cmd: TxCommand) => void;
  t: TFunction<"modals">;
  blockchainErrorsT: TFunction<"blockchainErrors">;
  commonT: TFunction<"common">;
}

export type TxCommand = StakeTxCommand | UnstakeTxCommand | ClaimTxCommand;
export type TxType = TxCommand["type"];

export class TxHandler<T extends TxCommand = TxCommand> {
  constructor(protected ctx: TxHandlerContext, protected cmd: T) {}

  public onConfirm() {
    this.ctx.openModal("confirmTxModal", this.createConfirmModalProps());
  }

  public onLoading() {
    this.ctx.openModal("loadingTxModal", this.createLoadingModalProps());
  }

  public onSuccess() {
    this.ctx.openModal("successTxModal", this.createSuccessModalProps());
  }

  public onError(e: any) {
    this.ctx.openModal("errorTxModal", this.createErrorModalProps(e));
  }

  public createConfirmModalProps(): ConfirmTxModalProps {
    return {
      onContinue: () => this.ctx.handleTx(this),
      onCancel: () => this.ctx.closeModal()
    };
  }

  public createLoadingModalProps(): LoadingTxModalProps {
    return {};
  }

  public createSuccessModalProps(): SuccessTxModalProps {
    return {
      onClose: () => this.ctx.closeModal()
    };
  }

  public createErrorModalProps(e: any): ErrorTxModalProps {
    return {
      onRetry: () => {
        this.ctx.closeModal();
        this.ctx.startTransaction(this.cmd);
      },
      onClose: () => this.ctx.closeModal(),
      info: {
        description: this.parseError(e)
      }
    };
  }

  public async sendTx() {}

  public parseError(e: any) {
    if (e instanceof ConnectorNotFoundError) {
      return this.ctx.t("errorMessages.notConnector");
    }

    if (e.code === 4001) {
      return this.ctx.t("errorMessages.rejected");
    }

    const decoded = decodeError(e.error?.data?.originalError?.data ?? "");
    if (decoded) return this.ctx.t("errorMessages.contract", { err: this.ctx.blockchainErrorsT(decoded as any) });

    if (e.reason && e.reason !== "execution reverted") {
      const msg = e.reason.replace("execution reverted: ", "");

      if (msg === "SafeERC20: low-level call failed") {
        return this.ctx.t("errorMessages.contract", { err: this.ctx.blockchainErrorsT("NotEnoughTokens") });
      }

      return this.ctx.t("errorMessages.contract", { err: msg });
    }

    return this.ctx.t("errorMessages.unknown");
  }

  protected formatSkey(value?: BigNumber) {
    return ethers.utils.formatUnits(value ?? BigNumber.from(0), SKEY_DECIMALS);
  }

  protected formatGas(gasLimit: number) {
    return ethers.utils.formatEther((this.ctx.feeData?.maxFeePerGas ?? BigNumber.from(0)).mul(gasLimit));
  }

  protected getBalanceAfter(earningPercent: BigNumber) {
    const { tokenBalance, stakedBalance } = this.ctx;

    if (tokenBalance && stakedBalance) {
      const earned = stakedBalance.mul(earningPercent).div(BigNumber.from(10 ** 12));
      return tokenBalance.value.add(stakedBalance).add(earned);
    }

    return BigNumber.from(0);
  }
}
