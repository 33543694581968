import { SKEY_DECIMALS } from "@src/config";
import { BigNumber, ethers } from "ethers";

export const calculateEarnings = (userBalance: BigNumber, earningPercent: BigNumber) => {
  const earnings = userBalance.mul(earningPercent).div(BigNumber.from(10 ** 12));
  return Number(ethers.utils.formatUnits(earnings, SKEY_DECIMALS));
};

export const calculateTotalRewards = (userBalance: BigNumber, earningPercent: BigNumber) => {
  const earnings = userBalance.mul(earningPercent).div(BigNumber.from(10 ** 12));
  const total = userBalance.add(earnings);
  return Number(ethers.utils.formatUnits(total, SKEY_DECIMALS));
};
