import { APP_STAGE, DEV_SHIFT_TIME } from "@src/config";

export const getCurrentDate = () => {
  if (APP_STAGE === "dev" && DEV_SHIFT_TIME) {
    return new Date(new Date().getTime() + DEV_SHIFT_TIME);
  }

  return new Date();
};

export const getCurrentDateMiliseconds = () => {
  if (APP_STAGE === "dev" && DEV_SHIFT_TIME !== 0) {
    return new Date().getTime() + DEV_SHIFT_TIME;
  }

  return new Date().getTime();
};
