import React from "react";
import { useTranslation } from "react-i18next";

import Backdrop from "@components/ux/Backdrop/Backdrop";
import Portal from "@components/overlay/Portal/Portal";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";

import OfflineIcon from "@src/assets/OfflineIcon";
import "./OfflineNetwork.scss";

const OfflineNetwork = () => {
  const { t } = useTranslation("guards");

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack alignItems="center" className="offline-network-modal">
            <OfflineIcon className="mb-5" />
            <h3 className="bold mb-1">{t("offlineNetwork.title")}</h3>
            <p className="p0">{t("offlineNetwork.description")}</p>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default OfflineNetwork;
