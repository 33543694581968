import React from "react";
import { useTranslation } from "react-i18next";

import Group from "@src/components/arrangement/Group/Group";
import Link from "@src/components/navigation/Link/Link";

import "./CopyrightBar.scss";

const CopyrightBar = () => {
  const { t } = useTranslation("layout");
  const year = new Date().getFullYear();

  return (
    <div className="copyright-bar">
      <p className="p1 white-5">{t("copyright.copy", { year })}</p>

      {/* <Group colGap={40}>
        <Link variant="secondary" size="small" href="" title={t("copyright.links.termsAndConditions.title")}>
          {t("copyright.links.termsAndConditions.label")}
        </Link>
        <Link variant="secondary" size="small" href="" title={t("copyright.links.websiteRegulations.title")}>
          {t("copyright.links.websiteRegulations.label")}
        </Link>
        <Link variant="secondary" size="small" href="" title={t("copyright.links.faq.title")}>
          {t("copyright.links.faq.label")}
        </Link>
      </Group> */}
    </div>
  );
};

export default CopyrightBar;
