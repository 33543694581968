import React from "react";
import { Chain, useNetwork } from "wagmi";
import { useTranslation } from "react-i18next";

import * as EthHooks from "@src/services/eth/EthHooks";
import { usePromise } from "@src/hooks/usePromise";
import { config } from "@src/services/eth/EthProvider";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import Button from "@src/components/dataEntry/Buttons/Button/Button";
import EtherumLogo from "@src/assets/EtherumLogo";

import "./SwitchNetwork.scss";

interface SwitchNetworkProps {
  onClose(): void;
}

const SwitchNetwork = ({ onClose }: SwitchNetworkProps) => {
  const { switchNetworkAsync } = EthHooks.useSwitchNetwork();
  const { call: switchNetwork, loading } = usePromise(switchNetworkAsync as () => Promise<Chain>);
  const network = useNetwork();

  const { t } = useTranslation("guards");
  const { t: commonT } = useTranslation("common");

  const networkName = config.network.name;
  const disabledSwitchNetwork = network?.chain?.id === config.network.id;

  const handleSwitchNetwork = () => switchNetwork();

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <Stack alignItems="center" className="switch-network-modal">
            <EtherumLogo className="mt-3 mb-5" />

            <h3 className="bold text-center mb-1">{t("switchNetwork.title", { networkName })}</h3>
            <p className="p0 text-center mb-5">{t("switchNetwork.description", { networkName })}</p>

            <Stack rowGap={24} fullWidth>
              <Button
                variant="primary"
                fullSize
                onClick={handleSwitchNetwork}
                loading={loading}
                disabled={disabledSwitchNetwork}
              >
                {t("switchNetwork.button")}
              </Button>
              <Button variant="secondary" onClick={onClose} fullSize>
                {commonT("close")}
              </Button>
            </Stack>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SwitchNetwork;
