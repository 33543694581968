import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { BlockchainAddress } from "@src/types/Blockchain.types";

import Stack from "@src/components/arrangement/Stack/Stack";
import Tooltip from "@src/components/dataDisplay/Tooltip/Tooltip";
import InfoCardIcon from "@src/assets/InfoCardIcon";
import CheckedCardIcon from "@src/assets/CheckedCardIcon";

import "./StakingVariantCard.scss";

interface StakingVariantCardProps {
  address: BlockchainAddress;
  id?: string;
  name?: string;
  value?: number;
  tip?: string;
  months?: number;
  percentage?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  active?: boolean;
  className?: string;
  onClick?(event: React.MouseEvent<HTMLButtonElement>, address: BlockchainAddress): void;
}

const StakingVariantCard = ({
  address,
  id,
  name,
  value,
  tip,
  months,
  percentage,
  disabled = false,
  fullWidth = false,
  active = false,
  className = "",
  onClick = () => {}
}: StakingVariantCardProps) => {
  const { t } = useTranslation("common");

  const classes = classNames(
    "staking-variant-card",
    { "staking-variant-card--active": active },
    { "full-width": fullWidth },
    className
  );

  return (
    <button
      id={id}
      name={name}
      value={value}
      disabled={disabled}
      onClick={(e) => onClick?.(e, address)}
      className={classes}
    >
      {tip && (
        <div className="staking-variant-card-info-icon">
          <Tooltip tip={tip} width={220}>
            <InfoCardIcon />
          </Tooltip>
        </div>
      )}

      <Stack rowGap={4} fullSize alignItems="center" justifyContent="center" className="staking-variant-card-content">
        <p className="p2 bold">
          {months} {t("months")}
        </p>
        <p className="p0">{percentage}</p>
        <CheckedCardIcon checked={active} />
      </Stack>
    </button>
  );
};

export default StakingVariantCard;
