import { SOCIAL_MEDIA_LINKS, TELEGRAM_CHANNELLS } from "@src/constants/constants";

export const socialMedia = [
  {
    title: "footer.followUs.links.twitter",
    logoPath: "/images/socialMedia/twitter.svg",
    url: SOCIAL_MEDIA_LINKS.TWITTER
  },
  {
    title: "footer.followUs.links.youtube",
    logoPath: "/images/socialMedia/youtube.svg",
    url: SOCIAL_MEDIA_LINKS.YOUTUBE
  },
  {
    title: "footer.followUs.links.reddit",
    logoPath: "/images/socialMedia/reddit.svg",
    url: SOCIAL_MEDIA_LINKS.REDDIT
  },
  {
    title: "footer.followUs.links.facebook",
    logoPath: "/images/socialMedia/facebook.svg",
    url: SOCIAL_MEDIA_LINKS.FACEBOOK
  },
  {
    title: "footer.followUs.links.discord",
    logoPath: "/images/socialMedia/discord.svg",
    url: SOCIAL_MEDIA_LINKS.DISCORD
  },
  {
    title: "footer.followUs.links.kakaotalk",
    logoPath: "/images/socialMedia/kakaotalk.svg",
    url: SOCIAL_MEDIA_LINKS.KAKAO_TALK
  },
  {
    title: "footer.followUs.links.medium",
    logoPath: "/images/socialMedia/medium.svg",
    url: SOCIAL_MEDIA_LINKS.MEDIUM
  }
];

export const telegramChannels = [
  {
    title: "footer.followUs.telegramChannels.telegram",
    label: "Skey Network News >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_1
  },
  {
    title: "footer.followUs.telegramChannels.telegram",
    label: "Skey Network Chat Official >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_2
  },
  {
    title: "footer.followUs.telegramChannels.telegram",
    label: "Skey Network Chat Korea >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_3
  },
  {
    title: "footer.followUs.telegramChannels.telegram",
    label: "Skey Network Chat PL >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_4
  }
];
