import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@src/components/arrangement/Stack/Stack";
import ConncectMetamask from "@src/components/dataEntry/Buttons/ConncectMetamask/ConncectMetamask";

const HeroStakingNotConnected = () => {
  const { t } = useTranslation("staking");

  return (
    <Stack rowGap={40} alignItems="center">
      <Stack rowGap={8} alignItems="center">
        <h1 className="light">{t("hero.stakingNotConnected.title")}</h1>
        <p className="p0">{t("hero.stakingNotConnected.description")}</p>
      </Stack>

      <ConncectMetamask className="staking-hero-button" />
    </Stack>
  );
};

export default HeroStakingNotConnected;
