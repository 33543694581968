import React from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";

import Portal from "@src/components/overlay/Portal/Portal";
import Backdrop from "@src/components/ux/Backdrop/Backdrop";
import Modal from "@src/components/overlay/Modal/Modal";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import WalletAddress from "@src/components/common/WalletAddress/WalletAddress";
import ModalStatusIcon from "@src/components/ux/ModalStatusIcon/ModalStatusIcon";
import Button from "@src/components/dataEntry/Buttons/Button/Button";

import "./SuccessTxModal.scss";

export interface SuccessTxModalProps {
  title?: string;
  info?: {
    title?: string;
    description?: string;
  };
  closeText?: string;
  onClose?(): void;
}

const SuccessTxModal = ({ title, info, closeText, onClose }: SuccessTxModalProps) => {
  const { address } = useAccount();
  const { t } = useTranslation("modals");

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack rowGap={24} alignItems="center" className="success-tx-modal">
            <Group justifyContent="flex-end" fullWidth>
              <WalletAddress address={address ?? ""} />
            </Group>
            <ModalStatusIcon variant="success" />

            <h2>{title ?? t("txModalSuccessDefault.title")}</h2>
            <div className="description text-center">
              <p className="p0 bold">{info?.title ?? t("txModalSuccessDefault.info.title")}</p>
              <p className="p0">{info?.description ?? t("txModalSuccessDefault.info.description")}</p>
            </div>

            <Stack rowGap={24} fullWidth className="mt-3">
              {onClose && (
                <Button fullSize onClick={onClose}>
                  {closeText ?? t("txModalSuccessDefault.closeText")}
                </Button>
              )}
            </Stack>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SuccessTxModal;
