import React from "react";

interface Icon {
  width?: number;
  height?: number;
  className?: string;
}

const MobileDevice = ({ width = 100, height = 100, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.5508 2.79111C21.6708 2.28444 22.9108 2 24.2219 2H59.7774C62.1349 2 64.3959 2.9365 66.0628 4.60349C67.7298 6.27049 68.6663 8.53141 68.6663 10.8889V50.8889M68.6663 68.6667V73.1111C68.6663 75.4686 67.7298 77.7295 66.0628 79.3965C64.3959 81.0635 62.1349 82 59.7774 82H24.2219C21.8644 82 19.6035 81.0635 17.9365 79.3965C16.2695 77.7295 15.333 75.4686 15.333 73.1111V15.3333"
      stroke="url(#paint0_linear_360_3548)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.5557 6.44446H46.4446"
      stroke="url(#paint1_linear_360_3548)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 64.2224V64.2662"
      stroke="url(#paint2_linear_360_3548)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 2L82 82"
      stroke="url(#paint3_linear_360_3548)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_360_3548"
        x1="68.6663"
        y1="77.5671"
        x2="16.7145"
        y2="77.4727"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_360_3548"
        x1="46.4446"
        y1="7.38905"
        x2="37.791"
        y2="7.1793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_360_3548"
        x1="43"
        y1="64.2638"
        x2="42.0297"
        y2="64.2034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_360_3548"
        x1="82"
        y1="77.5671"
        x2="4.07254"
        y2="77.3546"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
    </defs>
  </svg>
);

export default MobileDevice;
