import React, { useMemo } from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { formatDateTimeUTC } from "@src/utils/formatDateTime";
import { scrollToSectionOffset } from "@src/utils/scrollTo";

import Stack from "@src/components/arrangement/Stack/Stack";
import CountdownTimer from "@src/components/dataDisplay/CountdownTimer/CountdownTimer";
import Button from "@src/components/dataEntry/Buttons/Button/Button";
import ConncectMetamask from "@src/components/dataEntry/Buttons/ConncectMetamask/ConncectMetamask";

const HeroPrelaunch = () => {
  const { isConnected } = useAccount();
  const { instancesData } = useStakingsData();

  const { t } = useTranslation("staking");

  const targetDate = useMemo(() => {
    return instancesData?.[0].stages.subscribeStageTo || 0;
  }, [instancesData]);

  const handleScrollToDeposit = () => scrollToSectionOffset("prelaunch-stage", 90);

  return (
    <Stack rowGap={48} alignItems="center">
      <h1 className="light">{t("hero.prelaunchStarted.title")}</h1>

      <Stack rowGap={26} alignItems="center">
        <CountdownTimer targetDate={targetDate} />
        <p className="p0">{formatDateTimeUTC(targetDate)}</p>
      </Stack>

      {isConnected ? (
        <Button title={t("hero.depositButton.title")} onClick={handleScrollToDeposit} className="staking-hero-button">
          {t("hero.depositButton.label")}
        </Button>
      ) : (
        <ConncectMetamask className="staking-hero-button" />
      )}
    </Stack>
  );
};

export default HeroPrelaunch;
