import { BigNumber, ethers } from "ethers";
import { prepareWriteContract, readContract, writeContract } from "wagmi/actions";
import { OldToken__factory, StakingAggregator__factory } from "staking-contract";

import { SKEY_DECIMALS } from "@src/config";
import { CURRENCY_SKEY } from "@src/constants/constants";
import { StakingData } from "@src/contexts/StakingsDataContext";

import { ConfirmTxModalProps } from "@src/components/modals/ConfirmTxModal/ConfirmTxModal";
import { LoadingTxModalProps } from "@src/components/modals/LoadingTxModal/LoadingTxModal";
import { SuccessTxModalProps } from "@src/components/modals/SuccessTxModal/SuccessTxModal";

import { config } from "../EthProvider";
import { TxHandler } from "./TxHandler";

export interface StakeTxCommand {
  type: "stake";
  args: {
    value: string;
    data: StakingData;
  };
}

export class StakeTxHandler extends TxHandler<StakeTxCommand> {
  private readonly GAS_LIMIT = 46431 + 80000;

  override createConfirmModalProps(): ConfirmTxModalProps {
    return {
      ...super.createConfirmModalProps(),
      continueText: this.ctx.t("txModalStake.continue"),
      textRows: [
        {
          key: this.ctx.t("txModalStake.rows.value"),
          value: `${this.cmd.args.value} ${CURRENCY_SKEY}`
        },
        {
          key: this.ctx.t("txModalStake.rows.remainingFunds"),
          value: `${this.formatSkey(
            this.ctx.tokenBalance?.value.sub(ethers.utils.parseUnits(this.cmd.args.value, SKEY_DECIMALS))
          )} ${CURRENCY_SKEY}`
        },
        {
          key: this.ctx.t("txModalStake.rows.fee"),
          value: this.formatGas(this.GAS_LIMIT)
        },
        {
          key: this.ctx.t("txModalStake.rows.duration"),
          value: `${this.cmd.args.data.months} ${this.ctx.commonT("months")}`
        },
        {
          key: this.ctx.t("txModalStake.rows.bonus"),
          value: this.cmd.args.data.data.earningPercent.percent
        }
      ]
    };
  }

  override createLoadingModalProps(): LoadingTxModalProps {
    return {
      ...super.createLoadingModalProps(),
      info: {
        title: this.ctx.t("txModalLoadingStake.info.title", {
          value: this.cmd.args.value
        })
      }
    };
  }

  override createSuccessModalProps(): SuccessTxModalProps {
    return {
      ...super.createSuccessModalProps(),
      title: this.ctx.t("txModalSuccessStake.title"),
      info: {
        title: this.ctx.t("txModalSuccessStake.info.title", {
          value: this.cmd.args.value
        }),
        description: this.ctx.t("txModalSuccessStake.info.description")
      }
    };
  }

  override async sendTx() {
    const value = ethers.utils.parseUnits(this.cmd.args.value, SKEY_DECIMALS);
    const currentAllowance = await this.getCurrentAllowance();

    if (currentAllowance.lt(value)) {
      await this.sendApproveTx(value);
    }

    await this.sendStakeTx(value);
  }

  private async getCurrentAllowance() {
    return await readContract({
      abi: OldToken__factory.abi,
      address: config.tokenAddress,
      functionName: "allowance",
      args: [this.ctx.userAddress, this.cmd.args.data.addr]
    });
  }

  private async sendApproveTx(value: BigNumber) {
    const tx = await prepareWriteContract({
      abi: OldToken__factory.abi,
      address: config.tokenAddress,
      functionName: "approve",
      args: [this.cmd.args.data.addr, value]
    });

    await writeContract(tx).then((receipt) => receipt.wait());
  }

  private async sendStakeTx(value: BigNumber) {
    const tx = await prepareWriteContract({
      abi: StakingAggregator__factory.abi,
      address: config.aggregatorAddress,
      functionName: "increaseDeposit",
      args: [BigNumber.from(this.cmd.args.data.index), value]
    });

    await writeContract(tx).then((receipt) => receipt.wait());
  }
}
