import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@src/components/arrangement/Stack/Stack";

const HeroFinishedConnected = () => {
  const { t } = useTranslation("staking");

  return (
    <Stack rowGap={48} alignItems="center">
      <Stack rowGap={8} alignItems="center">
        <h1 className="light">{t("hero.finishedConnected.title")}</h1>
        <p className="p0">{t("hero.finishedConnected.description")}</p>
      </Stack>
    </Stack>
  );
};

export default HeroFinishedConnected;
