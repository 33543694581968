import React, { useEffect } from "react";

import useOnClickOutside from "@src/hooks/useOnClickOutside";
import useLockedBody from "@src/hooks/useLockedBody";
import useClickKey from "@src/hooks/useClickKey";

import "./Modal.scss";

interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;
}

const Modal = ({ children, onClose }: ModalProps) => {
  const modalRef = React.useRef(null);
  const { handleLocked, handleUnlocked } = useLockedBody();

  const handleClose = () => {
    if (!!onClose) return onClose();
  };

  useOnClickOutside(modalRef, handleClose);
  useClickKey("Escape", handleClose);

  useEffect(() => {
    handleLocked();

    return () => {
      handleUnlocked();
    };
  }, []);

  return (
    <div ref={modalRef} className="modal">
      {children}
    </div>
  );
};

export default Modal;
