import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking } from "@src/services/eth/EthHooks";

import { BlockchainAddress } from "@src/types/Blockchain.types";
import { formatDateTimeUTC } from "@src/utils/formatDateTime";
import { scrollToSectionOffset } from "@src/utils/scrollTo";

import Stack from "@src/components/arrangement/Stack/Stack";
import CountdownTimer from "@src/components/dataDisplay/CountdownTimer/CountdownTimer";
import Button from "@src/components/dataEntry/Buttons/Button/Button";

const HeroClaimingConnectedAssigned = () => {
  const { instancesData, getInstanceData } = useStakingsData();
  const { data: assignedStaking } = useAssignedStaking();

  const { t } = useTranslation("staking");

  const assignedStakingData = useMemo(() => {
    return getInstanceData(assignedStaking as BlockchainAddress);
  }, [assignedStaking, getInstanceData]);

  const targetDate = useMemo(() => {
    return assignedStakingData?.stages.claimStageTo || 0;
  }, [instancesData]);

  const handleScrollToClaiming = () => scrollToSectionOffset("claiming-stage", 90);

  return (
    <Stack rowGap={48} alignItems="center">
      <h1 className="light">{t("hero.claimingConnectedAssigned.title")}</h1>

      <Stack rowGap={26} alignItems="center">
        <CountdownTimer targetDate={targetDate} />
        <p className="p0">{formatDateTimeUTC(targetDate)}</p>
      </Stack>

      <Button title={t("hero.claimingButton.title")} onClick={handleScrollToClaiming} className="staking-hero-button">
        {t("hero.claimingButton.label")}
      </Button>
    </Stack>
  );
};

export default HeroClaimingConnectedAssigned;
