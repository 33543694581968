import React from "react";
import classNames from "classnames";
import "./Spinner.scss";

interface SpinnerProps {
  circleSize?: number;
  variant?: "primary" | "secondary";
  className?: string;
}

const Spinner = ({ circleSize = 30, variant = "primary", className }: SpinnerProps) => {
  const injectedVariables = {
    "--circle-size": `${circleSize}px`
  };

  const classes = classNames("spinner", `spinner-svg-circle-${variant}`, className);

  return (
    <div style={injectedVariables as React.CSSProperties} className={classes}>
      <svg className="spinner-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx={50} cy={50} r={30} className="spinner-svg-circle" />
      </svg>
    </div>
  );
};

export default Spinner;
