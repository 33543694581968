import React, { useState } from "react";
import { nanoid } from "nanoid";
import { usePopper } from "react-popper";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import useVisible from "@src/hooks/useVisible";
import ClickAwayListener from "@src/components/common/ClickAwayListener/ClickAwayListener";
import Link from "../Link/Link";

import "./SocialIconWithDropdown.scss";

interface SocialLinks {
  title?: string;
  label: string;
  url: string;
}

interface SocialIconWithDropdownProps {
  logoPath: string;
  title?: string;
  links: SocialLinks[];
  className?: string;
}

const SocialIconWithDropdown = ({ logoPath, title, links, className }: SocialIconWithDropdownProps) => {
  const [tooltipRef, setTooltipRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const { isVisible, visibilityToggle, setVisibilityFalse } = useVisible(false);

  const { t } = useTranslation("layout");

  const { styles, attributes } = usePopper(tooltipRef, popperRef, {
    placement: "left",
    strategy: "absolute",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, -15]
        }
      },
      {
        name: "preventOverflow",
        options: {
          rootBoundary: "document",
          padding: 0
        }
      }
    ]
  });

  const classess = classNames("socialicon-withDropdown", className);

  return (
    <ClickAwayListener onClickAway={setVisibilityFalse}>
      <div className={classess}>
        <div role="button" title={title} className="socialicon-withDropdown-target">
          <img
            src={logoPath}
            alt="social media button"
            width="31"
            height="30"
            ref={setTooltipRef}
            onClick={visibilityToggle}
            className="op-sm-50"
          />
        </div>

        {isVisible && (
          <div
            ref={setPopperRef}
            role="dropdown"
            style={{ ...styles.popper, zIndex: 100 }}
            className="socialicon-withDropdown-dropdown"
            {...attributes.popper}
          >
            <p className="p0 white-5">Telegram</p>

            {links.map(({ title, label, url }) => (
              <Link key={nanoid()} variant="secondary" href={url} target="_blank" title={t(title as any)}>
                {label}
              </Link>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SocialIconWithDropdown;
