import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { formatDateTimeUTC } from "@src/utils/formatDateTime";

import Stack from "@src/components/arrangement/Stack/Stack";
import CountdownTimer from "@src/components/dataDisplay/CountdownTimer/CountdownTimer";

const HeroBeforePrelaunch = () => {
  const { instancesData } = useStakingsData();
  const { t } = useTranslation("staking");

  const targetDate = useMemo(() => {
    return instancesData?.[0].stages.subscribeStageFrom || 0;
  }, [instancesData]);

  return (
    <Stack rowGap={40} alignItems="center">
      <Stack rowGap={8} alignItems="center">
        <h1 className="light">{t("hero.before.title")}</h1>
        <p className="p0">{t("hero.before.description")}</p>
      </Stack>

      <Stack rowGap={26} alignItems="center">
        <CountdownTimer targetDate={targetDate} />
        <p className="p0">{formatDateTimeUTC(targetDate)}</p>
      </Stack>
    </Stack>
  );
};

export default HeroBeforePrelaunch;
