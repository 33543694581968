import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useConnect as rawUseConnect,
  useSwitchNetwork as rawUseSwitchNetwork
} from "wagmi";
import { ethers, BigNumber } from "ethers";

import { OldToken__factory, StakingAggregator__factory, Staking__factory } from "staking-contract";
import { BlockchainAddress } from "@src/types/Blockchain.types";
import { config, connector } from "./EthProvider";

export const useChainValidated = () => {
  const acc = useAccount();
  const network = useNetwork();

  return !!acc.address && network.chain?.id === config.network.id;
};

export const useETHBalance = () => useBalance({ address: useAccount().address, watch: true });

export const useConnect = () => rawUseConnect({ chainId: config.network.id, connector: connector });

export const useSwitchNetwork = () => rawUseSwitchNetwork({ chainId: config.network.id });

export const useTokenBalance = () =>
  useBalance({ address: useAccount().address, token: config.tokenAddress, enabled: useChainValidated(), watch: true });

export const useCurrentAllowance = (stakingAddress?: BlockchainAddress) =>
  useContractRead({
    abi: OldToken__factory.abi,
    address: config.tokenAddress,
    functionName: "allowance",
    args: [useAccount().address!, stakingAddress!],
    enabled: useChainValidated() && !!stakingAddress,
    watch: true
  });

export const useAssignedStaking = () =>
  useContractRead({
    abi: StakingAggregator__factory.abi,
    address: config.aggregatorAddress,
    functionName: "assigned",
    args: [useAccount().address!],
    watch: true,
    enabled: useChainValidated()
  });

export const useStakingInstances = () =>
  useContractRead({
    abi: StakingAggregator__factory.abi,
    address: config.aggregatorAddress,
    functionName: "getInstances",
    watch: true
  });

export const useApproveTx = (stakingAddress?: BlockchainAddress) => {
  const { config: tx } = usePrepareContractWrite({
    abi: OldToken__factory.abi,
    address: config.tokenAddress,
    functionName: "approve",
    args: [stakingAddress!, ethers.constants.MaxUint256],
    enabled: useChainValidated() && !!stakingAddress
  });

  return { ...useContractWrite(tx), tx: tx.request };
};

export const useStakeTx = (stakingIndex: number | null, value: BigNumber | null, enabled = true) => {
  const { config: tx } = usePrepareContractWrite({
    abi: StakingAggregator__factory.abi,
    address: config.aggregatorAddress,
    functionName: "increaseDeposit",
    args: [BigNumber.from(stakingIndex), value!],
    enabled: useChainValidated() && stakingIndex !== null && !!value && enabled
  });

  return { ...useContractWrite(tx), tx: tx.request };
};

export const useStakedBalance = () => {
  const assigned = useAssignedStaking();
  const acc = useAccount();

  return useContractRead({
    abi: Staking__factory.abi,
    address: assigned.data,
    functionName: "balances",
    args: [acc.address!],
    enabled: useChainValidated() && !!acc.address && !!assigned.data && assigned.data !== ethers.constants.AddressZero,
    watch: true
  });
};
