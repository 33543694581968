import React from "react";
import { useTranslation } from "react-i18next";

import { CURRENCY_SKEY } from "@src/constants/constants";
import { roundValue } from "@src/utils/roundValue";
import { numberWithSpaces } from "@src/utils/numberWithSpaces";

import "./WalletBalance.scss";

interface WalletBalanceProps {
  balance: number;
  currency?: string;
}

const WalletBalance = ({ balance, currency }: WalletBalanceProps) => {
  const { t } = useTranslation("common");

  const formattedBalance = numberWithSpaces(roundValue(balance));

  return (
    <p title={t("walletBalance")} className="p0 wallet-balance">
      <span className="bold">{formattedBalance}</span> {currency || CURRENCY_SKEY}
    </p>
  );
};

export default WalletBalance;
