import React from "react";
import classNames from "classnames";
import { IChildren } from "src/types/IChildren";
import "./Stack.scss";

export interface IStack {
  colGap?: number;
  rowGap?: number;
  justifyContent?: React.CSSProperties["justifyContent"];
  alignItems?: React.CSSProperties["alignItems"];
  wrap?: React.CSSProperties["flexWrap"];
  fullSize?: boolean;
  fullWidth?: boolean;
  className?: string;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onClick?(): void;
  onBlur?(): void;
}

const Stack = ({
  colGap = 0,
  rowGap = 0,
  children,
  justifyContent = "flex-start",
  alignItems = "flex-start",
  wrap,
  fullSize = false,
  fullWidth = false,
  className = "",
  onMouseEnter,
  onMouseLeave,
  onClick,
  onBlur
}: IStack & IChildren) => {
  const classes = classNames("stack", { "full-width": fullWidth }, { "full-size": fullSize }, className);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onBlur={onBlur}
      className={classes}
      style={{
        gap: `${rowGap}px ${colGap}px`,
        justifyContent,
        alignItems,
        flexWrap: wrap
      }}
    >
      {children}
    </div>
  );
};

export default Stack;
