import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import Container from "@src/components/arrangement/Container/Container";
import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Buttons/Button/Button";

import NotFoundIcon from "@src/assets/NotFoundIcon";
import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("notFound");

  const goBack = () => navigate(-1);

  return (
    <Container size="xxl" fullSize>
      <Stack alignItems="center" justifyContent="center" fullSize className="not-found">
        <NotFoundIcon className="mb-5" />
        <h1 className="mb-1">{t("title")}</h1>
        <p className="p0 mb-4 not-found-description">{t("description")}</p>
        <Button onClick={goBack}>{t("goBack")}</Button>
      </Stack>
    </Container>
  );
};

export default NotFound;
