import React from "react";

interface Icon {
  width?: number;
  height?: number;
  className?: string;
}

const EtherumLogo = ({ width = 80, height = 80, className }: Icon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 414 414"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M207.141 413.99C321.353 413.99 413.94 321.381 413.94 207.142C413.94 92.9024 321.353 0.293152 207.141 0.293152C92.9296 0.293152 0.342773 92.9024 0.342773 207.142C0.342773 321.381 92.9296 413.99 207.141 413.99Z"
      fill="url(#paint0_linear_344_3558)"
    />
    <path d="M213.586 51.9769V166.654L310.484 209.969L213.586 51.9769Z" fill="white" fillOpacity="0.602" />
    <path d="M213.586 51.9769L116.688 209.969L213.586 166.654V51.9769Z" fill="white" />
    <path d="M213.586 284.272V362.193L310.54 228.008L213.586 284.272Z" fill="white" fillOpacity="0.602" />
    <path d="M213.586 362.193V284.272L116.688 228.008L213.586 362.193Z" fill="white" />
    <path d="M213.586 266.233L310.484 209.969L213.586 166.654V266.233Z" fill="white" fillOpacity="0.2" />
    <path d="M116.688 209.969L213.586 266.233V166.654L116.688 209.969Z" fill="white" fillOpacity="0.602" />
    <defs>
      <linearGradient
        id="paint0_linear_344_3558"
        x1="413.94"
        y1="391.067"
        x2="11.0577"
        y2="389.968"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3495FF" />
        <stop offset="1" stopColor="#5C44EC" />
      </linearGradient>
    </defs>
  </svg>
);

export default EtherumLogo;
