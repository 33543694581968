import React from "react";
import { useTranslation } from "react-i18next";

import { useModals } from "@src/contexts/ModalsContext";
import { METAMASK_DOWNLOAD_URL } from "@src/constants/constants";

import Portal from "@src/components/overlay/Portal/Portal";
import Backdrop from "@src/components/ux/Backdrop/Backdrop";
import Modal from "@src/components/overlay/Modal/Modal";
import Stack from "@src/components/arrangement/Stack/Stack";
import Link from "@src/components/navigation/Link/Link";
import Button from "@src/components/dataEntry/Buttons/Button/Button";

import "./MetamaskUnavailable.scss";

const MetamaskUnavailable = () => {
  const { closeModal } = useModals();

  const { t } = useTranslation("modals");
  const { t: commonT } = useTranslation("common");

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack alignItems="center" className="metamask-unavailable-modal">
            <img src="/images/metamask-logo.svg" alt="Metamask logo" width={120} height={110} />

            <h3 className="bold text-center mt-5 mb-2">{t("metamaskUnavailable.title")}</h3>
            <p className="p0 text-center">{t("metamaskUnavailable.description")}</p>

            <Link href={METAMASK_DOWNLOAD_URL} className="mt-3">
              {t("metamaskUnavailable.downloadMetamaskLink")}
            </Link>

            <Button variant="secondary" fullSize onClick={closeModal} className="mt-5">
              {commonT("close")}
            </Button>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default MetamaskUnavailable;
