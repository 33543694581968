import { ethers } from "ethers";
import { SKEY_DECIMALS } from "@src/config";

export const parseToBigNumber = (value: string) => {
  try {
    const num = Number(value);
    if (!Number.isFinite(num)) throw new Error();

    return ethers.utils.parseUnits(value, SKEY_DECIMALS);
  } catch {
    return null;
  }
};
