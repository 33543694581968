import React from "react";
import classNames from "classnames";
import "./Container.scss";

type ContainerSizes = "xxl" | "xl";

interface ContainerProps {
  children: React.ReactNode;
  size?: ContainerSizes;
  fullSize?: boolean;
  padding?: React.CSSProperties["padding"];
  className?: string;
}

const Container = ({ children, size = "xxl", fullSize, padding, className }: ContainerProps) => {
  const classes = classNames("app-container", `app-container-${size}`, { "full-size": fullSize }, className);

  return (
    <div className={classes} style={{ padding }}>
      {children}
    </div>
  );
};

export default Container;
