export const scrollToSection = (
  sectionId: string,
  behavior: ScrollBehavior = "smooth",
  block: ScrollLogicalPosition = "start",
  inline: ScrollLogicalPosition = "start"
) => {
  const section = document.getElementById(sectionId);

  if (section) {
    section.scrollIntoView({ behavior, block, inline });
  }
};

export const scrollToSectionOffset = (sectionId: string, offsetTopValue = 0) => {
  const section = document.getElementById(sectionId);

  if (section) {
    window.scrollTo({
      behavior: "smooth",
      top: section.offsetTop - offsetTopValue
    });
  }
};

export const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
