import React, { useMemo } from "react";
import { useAccount } from "wagmi";
import { ethers } from "ethers";

import { BlockchainAddress } from "@src/types/Blockchain.types";
import { useStakingsData } from "@src/contexts/StakingsDataContext";
import { useAssignedStaking } from "@src/services/eth/EthHooks";

import Container from "@src/components/arrangement/Container/Container";

import {
  HeroBeforePrelaunch,
  HeroPrelaunch,
  HeroStakingNotConnected,
  HeroStakingConnectedNotAssigned,
  HeroStakingConnectedAssigned,
  HeroClaimingConnectedAssigned,
  HeroFinishedConnected
} from "./variants";

import "./Hero.scss";

const Hero = () => {
  const { isConnected } = useAccount();
  const { data: assignedStaking } = useAssignedStaking();
  const { instancesData, getInstanceData } = useStakingsData();

  const content = useMemo(() => {
    if (!instancesData) {
      return <HeroBeforePrelaunch />;
    }
    // before pre-launch stage
    else if (instancesData?.[0].status === "pending") {
      return <HeroBeforePrelaunch />;
    }
    // pre-launch stage started
    else if (instancesData?.[0].status === "subscribe") {
      return <HeroPrelaunch />;
    }
    // pre-launch stage ended & is not connected
    else if (!isConnected) {
      return <HeroStakingNotConnected />;
    }
    // isConnected
    else if (isConnected) {
      // pre-launch stage ended & is connected & not assigned
      if (assignedStaking === ethers.constants.AddressZero) {
        return <HeroStakingConnectedNotAssigned />;
      }

      const currentStatus = getInstanceData(assignedStaking as BlockchainAddress)?.status;

      // staking stage ended & is connected & assigned
      if (currentStatus === "earn") {
        return <HeroStakingConnectedAssigned />;
      }
      // assignedStaking and isConnected and claim
      else if (currentStatus === "claim") {
        return <HeroClaimingConnectedAssigned />;
      }
      // staking is finished
      else if (currentStatus === "finished") {
        return <HeroFinishedConnected />;
      }
    }
  }, [instancesData, isConnected, assignedStaking]);

  return (
    <div className="staking-hero">
      <img
        src="/images/staking-hero-bg.png"
        alt="hero section background"
        width={1520}
        height={1000}
        className="staking-hero-background"
      />

      <Container size="xxl">{content}</Container>
    </div>
  );
};

export default Hero;
